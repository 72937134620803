<template>

<div class="position-fixed mt-2 top-10 end-1 z-index-1">
  <argon-snackbar
    v-if="snackbar"
    :title="snackbar.title"
    :description="snackbar.message"
    :icon="{ component: 'ni ni-spaceship', color: 'white' }"
    :color="snackbar.color"
    :close-handler="closeSnackbar"
  />
</div>


  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Adicionar funcionário</h5>
              </div>
            </div>
          </div>
          <div class="px-4 pb-3 card-body">

            <EmployeeForm @saveEmployee="employeeCreate"/>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/utils/api.js";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import EmployeeForm from "./components/EmployeeForm.vue"

export default {
  name: "EmployeeCreate",
  components: {
      ArgonSnackbar,
      EmployeeForm,
  },
  data(){
    return {
      snackbar: null,
    }
  },
  methods: {
    employeeCreate(formData){

      let formAux = Object.assign({}, formData);

      let date = formData.birthday.split("-");

      formAux.birthday = date[2] + "/" + date[1] + "/" + date[0];
        
      this.$swal.showLoading()
      api
        .post('/employees/create', formAux)
        .then((response) => {
          this.$swal.close()

            if(response.data.type == 'danger'){
              let error = Object.keys(response.data.errors)[0];

              this.snackbar = {
                message: response.data.errors[error],
                color: 'danger',
                title: "Erro ao salvar funcionário!",
              };

            } else {
              this.snackbar = {
                message: response.data.message,
                color: 'success',
                title: "Sucesso!",
              };
              this.$router.push({name: 'employees-list'});
            }
            
        })
        .catch((error) => {
          this.$swal.close()
            console.log("erro ao criar funcionário: ");
            console.log(error);
        });
    },
    closeSnackbar(){
      this.snackbar = null;
    },
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
};
</script>
