<template>
  <div class="card mx-4 mt-4 p-4">
    <form method="POST" id="edit_account_form" @submit.prevent="submitForm">
      <div class="row">
          <div class="col-sm-12 mb-2">
            Nome fantasia<br>
            <input type="text" class="form-control" name="PartnerForm[business_name]" :value="partner['business_name']">
          </div>
          <div class="col-sm-12 mb-2">
            Razão social<br>
            <input type="text" readonly class="form-control" :value="partner['name']">
          </div>
          <div class="col-sm-12 mb-2">
            Email<br>
            <input type="text" readonly class="form-control" :value="partner['email']">
          </div>
          <div class="col-sm-12 mb-2">
            CNPJ<br>
            <input type="text" readonly class="form-control" :value="partner['document']">
          </div>
      </div>
      <hr class="divider">
      <div class="row">
          <div class="col-sm-12 mb-2">
            <p class="fw-bold">Integração</p>
            Token Awise<br>
            <input type="text" class="form-control" name="PartnerForm[awise_token]" :value="partner['awise_token']">
          </div>
      </div>
      <hr class="divider">
      <div class="row">
          <div class="col-sm-6 mb-2">
            Nome do responsável 1<br>
            <input type="text" class="form-control" name="PartnerForm[contact_name]" :value="partner['contact_name']">
          </div>
          <div class="col-sm-6 mb-2">
            Nome do responsável 2<br>
            <input type="text" class="form-control" name="PartnerForm[contact_name_2]" :value="partner['contact_name_2']">
          </div>
          <div class="col-sm-6 mb-2">
            Celular do responsável 1<br>
            <input type="text" class="form-control" name="PartnerForm[cell]" :value="partner['cell']">
          </div>
          <div class="col-sm-6 mb-2">
            Celular do responsável 2<br>
            <input type="text" class="form-control" name="PartnerForm[cell2]" :value="partner['cell2']">
          </div>
          <div class="col-sm-6 mb-2">
            Telefone<br>
            <input type="text" class="form-control" name="PartnerForm[phone]" :value="partner['phone']">
          </div>
          <div class="col-sm-6 mb-2">
            CEP<br>
            <input type="text" class="form-control" name="PartnerForm[postal_code]" :value="partner['postal_code']">
          </div>
          <div class="col-sm-12 mb-2">
            Endereço<br>
            <input type="text" class="form-control" name="PartnerForm[address]" :value="partner['address']">
          </div>
          <div class="col-sm-6 mb-2">
            Número<br>
            <input type="text" class="form-control" name="PartnerForm[number]" :value="partner['number']">
          </div>
          <div class="col-sm-6 mb-2">
            Complemento<br>
            <input type="text" class="form-control" name="PartnerForm[complement]" :value="partner['complement']">
          </div>
          <div class="col-sm-12 mb-2">
            Bairro<br>
            <input type="text" class="form-control" name="PartnerForm[district]" :value="partner['district']">
          </div>
          <div class="col-sm-6 mb-2">
            Estado<br>
            <select id="state_list" name="PartnerForm[state_id]" v-on:change="getCities($event)" class="form-control">
              <option v-for="state in states" :key="state.id" :value="state.id">{{ state.name }}</option>
            </select>
          </div>
          <div class="col-sm-6 mb-2">
            Cidade<br>
            <select id="city_list" name="PartnerForm[city_id]" class="form-control">
              <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
            </select>
          </div>
      </div>
      <hr class="divider">
      <div class="row">
        <div class="col-sm-6 mb-2">
          Logo<br>
          <input type="file" name="PartnerForm[logo]" v-on:change="updateLogo($event)" class="form-control">
        </div>
        <div class="col-sm-6 mb-2">
          <img style="max-height: 128px" :src="logoUrl" alt="">
        </div>
      </div>
      <hr class="divider">
      <div class="row">
        <div class="col-sm-12 mb-2">
          Dados de login
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 mb-2">
          Nome usuario<br>
          <input type="text" class="form-control" name="UserForm[name]" :value="user['name']">
        </div>
        <div class="col-sm-6 mb-2">
          Email de acesso<br>
          <input type="text" class="form-control" name="UserForm[email]" :value="user['email']">
        </div>
        <div class="col-sm-6 mb-2">
          Senha de acesso<br>
          <input type="password" name="UserForm[password]" placeholder="Necessário caso altere os dados de login" class="form-control">
        </div>
        <div class="col-sm-6 mb-2">
          Verificar senha de acesso<br>
          <input type="password" name="UserForm[passwordVerify]" placeholder="Necessário caso altere os dados de login" class="form-control">
        </div>
      </div>
    
      <br>
      <div class="row justify-content-center">
        <div class="col-md-12">
          <button type="submit" class="btn w-100 btn-success btn-irispay">Salvar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import api from '@/utils/api.js';
import { nextTick } from 'vue'

export default {
  name: "Account",
  data() {
    return {
      showMenu: false,
      name:null,
      partner:[],
      user:[],
      states:[],
      cities:[],
      default_state: null,
      default_city: null,
      logoUrl: null,
    };
  },
  methods:{
    submitForm: function(){
      //panel-partner/beer/create
      let form = document.getElementById("edit_account_form");
      if(!form){ return; }
      var formData = new FormData(form);

      this.$swal.showLoading();
      api
        .post('/user/update', formData)
        .then(() => {
          this.$swal.close();

            this.$swal({
              title: 'Sucesso!',
              text: 'Os dados foram atualizados!',
              icon: "success",
              toast: true,
              position: "bottom-end",
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
            });

          this.$router.push({ name: 'Account' });
        })
        .catch((error) => {
          if(error.response){
            console.log(error.response);
            this.$swal({
              title: error.response.data.message,
              text: error.response.data.error,
              icon: "error",
              toast: true,
              position: "bottom-end",
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }); 
    },
    updateLogo: function(e){
      this.logoUrl = URL.createObjectURL(e.target.files[0]);
    },
    getCities: function(e){
      var id = e.target.value;
      this.searchCities(id);
    },
    searchCities: function(id, city_id = null){
      this.beer_manufacturers = [];

      this.$swal.showLoading();
      api
        .get("user/get-cities?state_id="+id)
        .then((response) => {
          this.$swal.close();
          this.cities = response.data;
          if(city_id)
          {
            setTimeout(function(){
              const select = document.querySelector('#city_list');
              select.value = city_id;
            }, 50);
          }
        })
        .catch((error) => {
          console.log("erro: ");
          console.log(error);
        }); 
    },
  },
  watch: {
    async states (){
      await nextTick();
      if(this.default_city && this.default_state){
        this.searchCities(this.default_state, this.default_city);
        this.default_state = null;
        this.default_city = null;
      }
    }
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;

    this.$swal.showLoading();

    api
      .get('/user/get-data')
      .then((response) => {
        this.$swal.close();
        console.log(response.data);
        this.partner = response.data.partner;
        this.user = response.data.user;
        this.states = response.data.states;
        this.default_state = response.data.partner.state_id;
        this.default_city = response.data.partner.city_id;

        if(response.data.partner.logo){
          this.logoUrl = "//assets.rtn.com.br/uploads/partner/logo/"+response.data.partner.logo;
        }

        setTimeout(function(){
          //Select my state
          const select = document.querySelector('#state_list');
          select.value = response.data.partner.state_id;
        }, 50);
      })
      .catch((error) => {
        console.log("erro: ");
        console.log(error);
      }); 

  },
  updated(){

  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
};
</script>