<template>

<div class="position-fixed mt-2 top-10 end-1 z-index-1">
  <argon-snackbar
    v-if="snackbar"
    :title="snackbar.title"
    :description="snackbar.message"
    :icon="{ component: 'ni ni-spaceship', color: 'white' }"
    :color="snackbar.color"
    :close-handler="closeSnackbar"
  />
</div>


  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Funcionários</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <!-- <a
                    href="/employees/create"
                    class="mb-0 btn bg-gradient-success btn-sm btn-irispay"
                    >+&nbsp; Adicionar novo funcionário</a
                  > -->

                  <router-link class="mb-0 btn bg-gradient-success btn-sm btn-irispay" :to="{ name: 'employees-create'}">
                        +&nbsp; Adicionar novo funcionário
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div v-if="employees"  class="px-4 pb-2 card-body">
            <div class="table-responsive">
              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Criado em</th>
                    <th>Status</th>
                    <th width="5%"></th>
                  </tr>
                </thead>

                <tbody>

                  <tr v-for="employee, index in employees" :key="employee.id">
                    
                    <td class="text-sm">{{employee.name}}</td>
                    <td class="text-sm">{{employee.email}}</td>
                    <td class="text-sm">{{timestampToDate(employee.created_at)}}</td>
                    <td class="text-sm">{{employee.status == 1 ? 'Ativo' : 'Inativo'}}</td>
                    <td class="text-sm">


                      <router-link class="mx-3" :to="{ name: 'employees-edit', params: { id: employee.id } }">
                        <i class="fas fa-edit text-secondary"></i>
                      </router-link>

                      <a
                        v-if="employee.status == 0"
                        href="javascript:;"
                        @click="activateEmployee(employee.id, index)"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Ativar funcionário"
                      >
                        <i class="fas fa-undo-alt text-secondary"></i>
                      </a>

                      <a
                        v-else
                        href="javascript:;"
                        @click="deleteEmployee(employee.id, employee.partner_id, index)"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Desativar funcionário"
                      >
                        <!-- <i class="fas fa-trash text-secondary"></i> -->
                        <i class="fas fa-ban text-secondary"></i>
                        
                      </a>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/utils/api.js";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";


export default {
  name: "PDVList",
  components: {
      ArgonSnackbar,
  },
  data(){
    return {
      employees: false,
      snackbar: null,
    }
  },
  mounted() {

    this.$swal.showLoading()

    api
      .get('/employees/get-employees')
      .then((response) => {

        this.$swal.close()

        this.employees = response.data.data;
        
        console.log("Funcionários retornados: ");
        console.log(this.employees);


      })
      .catch((error) => {

        this.$swal.close()

        console.log("erro ao obter PDVS: ");
        console.log(error);

      });
    
  },
  methods: {
    deleteEmployee(id, partner_id, index){

        this.$swal({
            title: "Tem certeza que deseja desativar o funcionário?",
            text: "É possível reverter essa ação.",
            showCancelButton: true,
            confirmButtonText: "Sim, quero desativar! ",
            cancelButtonText: "Não, cancelar!",
            reverseButtons: true,
            customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
            },
            buttonsStyling: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.$swal({
                    title: "Aguarde enquanto o seu funcionário é desativado...",
                    timerProgressBar: true,
                });

                api
                    .get('/employees/delete', {
                    params: {
                        id: id,
                        partnerId: partner_id,
                    },
                    })
                    .then((response) => {
                        this.$swal.close()
                        console.log("retorno delete");
                        console.log(response.data);
                        if(response.data.type === 'success')
                            this.employees[index].status = 0;

                        this.snackbar = {
                            message: response.data.message,
                            color: response.data.type,
                            title: "Atenção",
                        };
                        
                    })
                    .catch((error) => {
                        this.$swal.close()
                        console.log("erro ao desativar funcionário: ");
                        console.log(error);
                    });

            } else if (
                result.dismiss === this.$swal.DismissReason.cancel
            ) {
                this.$swal({
                    title: "Cancelado!",
                    text: "O seu funcionário não foi desativado!",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn bg-gradient-success",
                    },
                    buttonsStyling: false,
                });
            }
        });

    },
    activateEmployee(id, index){
        console.log("Ativar funcionário: ", id);


        this.$swal({
            title: "Tem certeza que deseja ativar o funcionário?",
            text: "É possível reverter essa ação.",
            showCancelButton: true,
            confirmButtonText: "Sim, quero ativar! ",
            cancelButtonText: "Não, cancelar!",
            reverseButtons: true,
            customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
            },
            buttonsStyling: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.$swal({
                    title: "Aguarde enquanto o seu funcionário é ativado...",
                    timerProgressBar: true,
                });

                api
                    .get('/employees/activate', {
                    params: {
                        id: id,
                    },
                    })
                    .then((response) => {
                        this.$swal.close()
                        console.log("retorno activate: ");
                        console.log(response.data);

                        if(response.data.type === 'success')
                            this.employees[index].status = 1;

                        this.snackbar = {
                            message: response.data.message,
                            color: response.data.type,
                            title: "Atenção",
                        };

                    })
                    .catch((error) => {
                        this.$swal.close()
                        console.log("erro ao ativar funcionário: ");
                        console.log(error);
                    });

            } else if (
                result.dismiss === this.$swal.DismissReason.cancel
            ) {
                this.$swal({
                    title: "Cancelado!",
                    text: "O seu funcionário não foi ativado!",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn bg-gradient-success",
                    },
                    buttonsStyling: false,
                });
            }
        });

    },
    timestampToDate(timestamp){
      return new Date(timestamp*1000).toLocaleString("pt-Br");
    },
    closeSnackbar(){
      this.snackbar = null;
    },
    
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },



};
</script>
