<template>


<div class="position-fixed mt-2 top-10 end-1 z-index-1">
  <argon-snackbar
    v-if="snackbar"
    :title="snackbar.title"
    :description="snackbar.message"
    :icon="{ component: 'ni ni-spaceship', color: 'white' }"
    :color="snackbar.color"
    :close-handler="closeSnackbar"
  />
</div>




  <div class="py-4 container-fluid">

    <h3 class="text-irispay">Créditos</h3>

    <div class="row mb-2">
      <default-statistics-card
        title="Limite de crédito mensal:"
        :count="averageSell"
        color='dark'
      />

      <default-statistics-card
        :title="`Crédito disponível neste mês (${availableAsPercent}):`"
        :count="creditsAvailable"
        color='dark'
      />
    </div>
    

    <div class="row mb-2">
      <div class="col-6">

        <div class="card">

          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Filtros</h5>
              </div>
            </div>
            
          </div>
          <form @submit.prevent="applyFilters">

            <div class="px-4 pb-2 card-body">

              <div class="row">
                <div class="col">
                  <label>Nome</label>
                  <input class="form-control" type="text" v-model="filter.name">
                </div>
                <div class="col">
                  <label>Email</label>
                  <input class="form-control" type="email" v-model="filter.email">
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <label>Valor</label>
                  <input class="form-control" type="string" v-model.lazy="filter.value" v-money="money">
                  
                </div>
                <div class="col">
                  <label>Início</label>
                  <input class="form-control" type="date" v-model="filter.initial_date_aux">
                </div>
                <div class="col">
                  <label>Fim</label>
                  <input class="form-control" type="date" v-model="filter.final_date_aux">
                </div>
              </div>
            </div>

            <div class="px-4 pb-2 card-footer">
            <argon-button class="m-1 btn-irispay">Filtrar</argon-button>
              <argon-button class="m-1 btn-irispay" @click="removeFilters">Limpar filtros</argon-button>  
            </div>  
          </form>
          
        </div>
      </div>

      <div class="col-6">

        <div class="card">

          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Adicionar créditos</h5>
              </div>
            </div>
            
          </div>

          <div class="px-4 pb-2 card-body">

            <div class="row">
              <div class="col">
                <label>Email</label>
                <input class="form-control" type="email" v-model="add.email">
              </div>       
            </div>
            <div class="row">
              <div class="col">
                <label>Valor</label>
                <input 
                  class="form-control" 
                  type="string" 
                  v-model="add.value"
                  v-model.lazy="add.value" v-money="money"
                >
              </div>  
            </div>
          </div>

          <div class="px-4 pb-2 card-footer">
            <argon-button class="m-1 btn-irispay" @click="save">Salvar</argon-button>       
          </div>
          
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">

        <div class="card">

          <div class="pb-2 card-body">

            <div class="table-responsive">
              <div class="row p-3">
                {{ getItemsOnPage() }}
              </div>
              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th class="fs-6">Codigo</th>
                    <th class="fs-6" width="20%">Nome</th>
                    <th class="fs-6">Email</th>
                    <th class="fs-6">Valor</th>
                    <th class="fs-6" width="10%">Início</th>
                    <th class="fs-6" width="10%">Fim</th>
                    <th class="fs-6">Funcionário</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="userCreditEntryItem in userCreditEntry" :key="userCreditEntryItem.id">
                    <td class="text-sm text-wrap">{{userCreditEntryItem.id}}</td>
                    <td class="text-sm text-wrap">{{userCreditEntryItem.user_name}}</td>
                    <td class="text-sm text-wrap">{{userCreditEntryItem.user_email}}</td>
                    <td class="text-sm text-wrap">R$ {{userCreditEntryItem.value}}</td>
                    <td class="text-sm text-wrap">{{timestampToDate(userCreditEntryItem.created_at)}}</td>
                    <td class="text-sm text-wrap">{{timestampToDate(userCreditEntryItem.updated_at)}}</td>
                    <td class="text-sm text-wrap">{{userCreditEntryItem.employee_name}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="mt-3 d-flex justify-content-around">

              <Pagination-Iris
                :totalPages="number_pages"
                :atualPage="filter.page"
                @goToPage="(n) => filter.page = n"
               />
                
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/utils/api.js";
import ArgonButton from "@/components/ArgonButton.vue";
import DefaultStatisticsCard from "../components/DefaultStatisticsCard.vue";
import PaginationIris from "@/components/PaginationIris.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import {VMoney} from 'v-money'

export default {
  components: {
    ArgonButton,
    ArgonSnackbar,
    PaginationIris,
    DefaultStatisticsCard,

  },
  directives: {money: VMoney},
  data() {
    return {
      filter: {
        name: null,
        email: null,
        value: null,
        created_at: null,
        initial_date_aux: null,
        updated_at: null,
        final_date_aux: null,
        page: 1,
      },
      add: {
        email: null,
        value: 0,
      },
      userCreditEntry: null,
      number_pages: null,
      total_items: null,
      averageSell: 0,
      creditsAvailable: 0,
      availableAsPercent: null,
      snackbar: null,
      money: {
          decimal: '.',
          thousands: ',',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false
        }
    };
  },
  beforeMount() {
    this.$store.state.layout = "custom";

    this.$swal.showLoading()

    api
      .get('/user-credit-entry/index',{
        params: {},
      })
      .then((response) => {

          this.userCreditEntry = response.data.data;
          this.number_pages = response.data.number_pages
          this.total_items = response.data.number_items_total
          this.averageSell = response.data.averageSell
          this.creditsAvailable = response.data.creditsAvailable
          this.availableAsPercent = response.data.availableAsPercent

          console.log("Informações iniciais salvas...");

          this.$swal.close()

      })
      .catch((error) => {
          this.$swal.close()
          console.log("erro ao recuperar dados de vendas funcionário: ");
          console.log(error);
      });

  },
  watch: {
    'filter.page'(){
      this.$swal.showLoading()
      api
        .get('/user-credit-entry/index',{
          params: this.filter,
        })
        .then((response) => {
            this.userCreditEntry = response.data.data;
            this.number_pages = response.data.number_pages
            this.total_items = response.data.number_items_total
            this.averageSell = response.data.averageSell
            this.creditsAvailable = response.data.creditsAvailable
            this.availableAsPercent = response.data.availableAsPercent

            this.$swal.close();

            console.log("Dados carregados para a página ", this.filter.page);
        })
        .catch((error) => {
            this.$swal.close();
            console.log("erro ao recuperar dados de vendas na página ", this.filter.page);
            console.log(error);
        });
    }
  },
  methods: {
    teste(){
      console.log("filtros: ");
      console.log(this.filter);
    },
    applyFilters(){
      console.log("Aplicando filtros...");

      if(this.filter.initial_date_aux){
        let date = this.filter.initial_date_aux.split("-");

        date = date[2] + "/" + date[1] + "/" + date[0]

        this.filter.created_at = date;
        
      }

      if(this.filter.final_date_aux){
        let date = this.filter.final_date_aux.split("-");

        date = date[2] + "/" + date[1] + "/" + date[0]

        this.filter.updated_at = date;
        
      }

      console.log(this.filter);

      let aux = this.filter.value

      this.filter.value = parseFloat(aux);

      if(this.filter.value == 0){
        this.filter.value = null;
      };
      
      this.$swal.showLoading()

      api
        .get('/user-credit-entry/index', {
          params: this.filter,
        })
        .then((response) => {
          
            console.log("Créditos filtrados...");
            console.log(this.filter);

            this.userCreditEntry = response.data.data
            this.number_pages = response.data.number_pages
            this.total_items = response.data.number_items_total
            this.averageSell = response.data.averageSell
            this.creditsAvailable = response.data.creditsAvailable
            this.availableAsPercent = response.data.availableAsPercent
            this.filter.value = aux;

            this.$swal.close()
        })
        .catch((error) => {
            this.$swal.close()
            console.log("erro ao filtrar créditos...");
            console.log(error);
        });
    },
    removeFilters(){

      console.log("Removendo filtros...");

      this.filter = {
        partner_id: null,
        pdv_name: null,
        tap_id: null,
        beer_id: null,
        value: null,
        amount: null,
        user_id: null,
        name: null,
        initial_date_aux: null,
        created_at: null,
        initial_hour: null,
        final_date_aux: null,
        updated_at: null,
        final_hour: null,
        page: 1,
      },

      this.$swal.showLoading()

      api
        .get('/user-credit-entry/index', {
          params: {},
        })
        .then((response) => {
            console.log("Filtros removidos...");

            this.userCreditEntry = response.data.data
            this.number_pages = response.data.number_pages
            this.total_items = response.data.number_items_total
            this.averageSell = response.data.averageSell
            this.creditsAvailable = response.data.creditsAvailable
            this.availableAsPercent = response.data.availableAsPercent

            this.$swal.close()
        })
        .catch((error) => {
            this.$swal.close()
            console.log("erro ao remover filtros...");
            console.log(error);
        });

    },
    getItemsOnPage(){
        return `Exibindo de ${ (this.filter.page - 1) * 30 } a ${ this.filter.page * 30 } de ${this.total_items} itens.`;
    },
    timestampToDate(timestamp){
      return new Date(timestamp*1000).toLocaleString("pt-Br");
    },
    closeSnackbar(){
      this.snackbar = null;
    },
    save(){
      console.log("Salvando créditos...");
      console.log(this.add);

      this.$swal({
          title: `Tem certeza que deseja adicionar ${this.add.value} reais em créditos?`,
          showCancelButton: true,
          confirmButtonText: "Sim, quero adicionar. ",
          cancelButtonText: "Não, cancelar!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {

            this.$swal({
              title: "Aguarde enquanto os créditos são adicionados..",
            });

            let aux = this.add.value;
            this.add.value = parseFloat(aux);

            api
              .post('/user-credit-entry/add-credit', this.add)
              .then((response) => {
                this.$swal.close()

                this.add.value = aux;

                if(response.data.status == 'error'){
                  let error = Object.keys(response.data.errors)[0];

                  this.snackbar = {
                    message: response.data.errors[error],
                    color: 'danger',
                    title: "Erro ao adicionar créditos!",
                  };
                  console.log("Erro ao salvar créditos...");

                } else {

                  console.log("Créditos salvos com sucesso!");

                  this.snackbar = {
                    message: "Créditos adicionados com sucesso!",
                    color: 'success',
                    title: "Sucesso!",
                  };
                }
              })
              .catch((error) => {
                this.$swal.close()
                  console.log("erro ao salvar créditos: ");
                  console.log(error);
              });

          } else if (
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal({
              title: "Cancelado!",
              text: "Adição de créditos cancelada!",
              icon: "error",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });

            console.log("Adição de créditos cancelada!");
          }
        });
    },
  },

};
</script>
