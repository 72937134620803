<template>

<div class="position-fixed mt-2 top-10 end-1 z-index-1">
  <argon-snackbar
    v-if="snackbar"
    :title="snackbar.title"
    :description="snackbar.message"
    :icon="{ component: 'ni ni-spaceship', color: 'white' }"
    :color="snackbar.color"
    :close-handler="closeSnackbar"
  />
</div>

  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Editar funcionário</h5>
              </div>
            </div>
          </div>
          <div class="px-4 pb-3 card-body">

            <EmployeeForm 
                v-if="data"
                @saveEmployee="employeeUpdate"
                :form="data"
            />

          </div>
        </div>
      </div>
    </div>
  </div>    

</template>
<script>
import api from "@/utils/api.js";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import EmployeeForm from "./components/EmployeeForm.vue"

export default {
  name: "EmployeeEdit",
  components: {
      ArgonSnackbar,
      EmployeeForm,
  },
  data(){
    return {
      data: null,
      snackbar: null,
      errorMessage: "",
    }
  },
  beforeCreate() {
      this.$swal.showLoading()
      api
        .get('/employees/get-employee-data?id=' + this.$route.params.id)
        .then((response) => {
          this.$swal.close()
          console.log("Employee data: ");
          console.log(response.data);

          this.data = {
                name: response.data.name,
                email: response.data.email,
                password: "",
                passwordVerify: "",
                allow_credit: response.data.allow_credit == '0' ? false : true,
                gender: response.data.gender,
                birthday: response.data.birthday,
          }

          console.log("Data selected");
          console.log(this.data);

          
        })
        .catch((error) => {
            console.log("erro ao consultar dados do funcionário: ");
            console.log(error);
        });
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
  methods: {
    employeeCreate(formData){

      console.log("Dados do formulário: ");
      console.log(formData);

      let formAux = Object.assign({}, formData);

      let date = formData.birthday.split("-");

      formAux.birthday = date[2] + "/" + date[1] + "/" + date[0];

      console.log("Dados do formulário após editar birthday: ");
      console.log(formAux);

        

      this.$swal.showLoading()
      api
        .post('/employees/create', formAux)
        .then((response) => {
          this.$swal.close()

            /* if(response.data.status == 'error'){
              let error = Object.keys(response.data.errors)[0];

              this.snackbar = {
                message: response.data.errors[error],
                color: 'danger',
                title: "Erro ao salvar PDV!",
              };

            } else {
              this.snackbar = {
                message: "PDV salvo com sucesso!",
                color: 'success',
                title: "Sucesso!",
              };
              this.$router.push({name: 'pdv-list'});
            } */

            console.log("Funcionário adicionado com sucesso! ");
            console.log(response.data);
        })
        .catch((error) => {
          this.$swal.close()
            console.log("erro ao criar pdv: ");
            console.log(error);
        });
    },
    closeSnackbar(){
      this.snackbar = null;
    },
    employeeUpdate(formData){

        let formAux = Object.assign({}, formData);

        let date = formData.birthday.split("-");

        formAux.birthday = date[2] + "/" + date[1] + "/" + date[0];

        this.$swal.showLoading();

        api
        .post('/employees/update?id=' + this.$route.params.id, formAux)
        .then((response) => {
            this.$swal.close();

            if(response.data.type == 'danger'){
              let error = Object.keys(response.data.errors)[0];

              this.snackbar = {
                message: response.data.errors[error],
                color: 'danger',
                title: "Erro ao atualizar dados do funcionário!",
              };

            } else {
              this.snackbar = {
                message: response.data.message,
                color: 'success',
                title: "Sucesso!",
              };

              this.$router.push({name: 'employees-list'});
            }

        })
        .catch((error) => {
          this.$swal.close()
            console.log("erro ao atualizar funcionário: ");
            console.log(error);
        });
    }
  }
};
</script>

