<template v-if="products != null">
  <div class="card mx-4 mt-4">
    <div class="table-responsive">
      <table class="table table-flush">
        <thead class="thead-light">
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Foto<br>
            <input style="visibility: hidden;" type="text" class="form-control"></th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nome<br>
            <input data-filter-field="true" name="ProductPartner[name]" v-on:keyup="checkSearchInput" type="text" class="form-control"></th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Categoria<br>
            <input data-filter-field="true" name="ProductPartner[category]" v-on:keyup="checkSearchInput" type="text" class="form-control"></th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Valor<br>
            <input data-filter-field="true" name="ProductPartner[value]" v-on:keyup="checkSearchInput" type="text" class="form-control"></th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Criado em<br>
            <!--<input data-filter-field="true" name="ProductPartner[created_at]" v-on:keyup="checkSearchInput" type="text" class="form-control">-->
            <input style="visibility: hidden;" type="text" class="form-control"></th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Atualizado em<br>
            <!--<input data-filter-field="true" name="ProductPartner[updated_at]" v-on:keyup="checkSearchInput" type="text" class="form-control">-->
            <input style="visibility: hidden;" type="text" class="form-control"></th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in products" :key="product.id">
            <td class="text-center"><img :src="'http://assets.rtn.com.br/uploads/product/image/thumbs/small/'+product.product.image" alt=""></td>
            <td>{{ product.product.name }}</td>
            <td>{{ product.product.category.name }}</td>
            <td>{{ (product.value ? product.value : "(não definido)") }}</td>
            <td>{{ product.created_at }}</td>
            <td>{{ product.updated_at }}</td>
            <td>              
              <router-link :to="{ name: 'ProductEdit', params: { id: product.id } }"><i class="fas fa-fw fa-pen"></i></router-link>
              <a v-if="product.status == 1" title="Desativar" href="#" @click="deleteRecord(product.id)"><i class="fas fa-fw fa-ban"></i></a>
              <a v-else title="Reativar" href="#" @click="restoreRecord(product.id)"><i class="fas fa-fw fa-undo"></i></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import api from '@/utils/api.js';

export default {
  name: "Product",
  data() {
    return {
      showMenu: false,
      products:null,
    };
  },
  methods:{
    deleteRecord: function(id){
      console.log("deleting: "+id);
      this.$swal({
        title: "Tem certeza?",
        text: "Você irá desativar este produto.",
        showCancelButton: true,
        confirmButtonText: "Sim, desativar.",
        cancelButtonText: "Não, cancelar.",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .get(`/product-partner/delete?id=${id}`)
            .then(() => {
              for(let i = 0; i < this.products.length; i++)
              {
                if(this.products[i].id == id)
                {
                  this.products[i].status = 0;
                  break;
                }
              }

              this.$swal({
                title: "Desativado!",
                text: "Produto desativado com sucesso.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 2500,
                timerProgressBar: true,
                showConfirmButton: false,
              });
            })
            .catch((error) => {
              console.log("erro: ");
              console.log(error);
            }); 
        }
      });
    },
    restoreRecord: function(id){
      console.log("restoring: "+id);
      this.$swal({
        title: "Tem certeza?",
        text: "Você irá reativar este produto",
        showCancelButton: true,
        confirmButtonText: "Sim, reativar.",
        cancelButtonText: "Não, cancelar.",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .get(`/product-partner/reactivate?id=${id}`)
            .then(() => {
              for(let i = 0; i < this.products.length; i++)
              {
                if(this.products[i].id == id)
                {
                  this.products[i].status = 1;
                  break;
                }
              }

              this.$swal({
                title: "Reativado!",
                text: "Produto reativado com sucesso.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 2500,
                timerProgressBar: true,
                showConfirmButton: false,
              });
            })
            .catch((error) => {
              console.log("erro: ");
              console.log(error);
            }); 
        }
      });
    },
    checkSearchInput: function (e) {
      if(e.key == "Enter")
      {
        this.doSearch();
      }
    },
    doSearch: function () {
      let final_url = '/product-partner/index?expand=product,product.category';
      let fields = document.querySelectorAll('[data-filter-field="true"]')
      fields.forEach(field => {
        if(field.value.length > 0){
        final_url += `&${field.name}=${field.value}`;
        }
      });

      this.$swal.showLoading();

      api
        .get(final_url)
        .then((response) => {
          this.$swal.close();
          this.products = response.data;
        })
        .catch((error) => {
          console.log("erro: ");
          console.log(error);
        }); 
    }
  },
  mounted() {
    this.$store.state.isAbsolute = true;

    setNavPills();
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;

    this.$swal.showLoading();

    api
      .get('/product-partner/index?expand=product,product.category')
      .then((response) => {
        this.$swal.close();
        this.products = response.data;
        console.log(response.data);
      })
      .catch((error) => {
        console.log("erro: ");
        console.log(error);
      }); 

  },
  updated(){

  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
};
</script>