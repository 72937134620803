import axios from 'axios';
import router from '@/router';

console.log(process.env);

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://api.rtn.com.br/panel-partner'
      : 'http://localhost:21082/panel-partner',
  withCredentials: true,
  headers: {
      Accept: '*/*',
      PrivateToken: 'dAySz6jZt9s25wQaZ5AcxnUA4leo2pS0Y6VwQ72mOXVAlwPapFXyxMu4aAw8Ej7y0m',
    },
});

// http://api-dev.rtn.com.br/panel-partner
// http://ec2-18-230-56-46.sa-east-1.compute.amazonaws.com/panel-partner

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  if (!token && router.currentRoute.value.name != 'reset-password') router.push('/login');

  if (config.headers === undefined) {
    config.headers = { Authorization: `Bearer ${token}` };
  } else {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});


 api.interceptors.response.use(
  (response) => response,
  (error) => {
    
    /* if (error.response.status === 401) {
      router.push('/login');
    } else {
      console.log(error.reponse);
    } */

    return Promise.reject(error);
  }
);

export default api;
