<template>
  <div class="card" v-if="tap != null && beer != null">
    <div class="p-3 card-body">

      

      <div class="p-3">

        <div class="row">
          <div class="col-3">
            <div class="p-2 card avatar avatar-xxl border-radius-md">
              <img :src="logo" :alt="title" />
            </div>
          </div>

           <div class="col-8">
             <div class="row">

               <!-- 
                Nome do parceiro
                ID e Nome da torneira
                Nome da bebida
                Fabricante
                IBU
                Alcoólico X.X% 
               -->
               <div class="col-8">
                 <div class="row">
                   {{partner_name}}
                 </div>
                 <div class="row">
                   #{{tap.id}} {{tap.name}}
                 </div>
                 <div class="row strong">
                    {{beer.name}}
                 </div>

                 <div class="row">
                   <div class="col">
                     {{ beer_manufacturer.title }}
                   </div>
                   <div class="col">
                     IBU {{ beer.ibu }}
                   </div>
                   <div class="col">
                     {{ beer.alcoholic_strength }}%
                   </div>
                     
                 </div>

               </div>

              <!-- 
                Capacidade
                Remanescente
               -->
               <div class="col">
                 <div class="row strong">
                    Volume
                 </div>
                 <div class="row">
                    Capacidade: {{tap.capacity}} litros
                 </div>
                 <div class="row">
                    Remanescente: {{tap.remaining}} litros
                 </div>
               </div>

             </div>
            
          </div>

          <div class="col d-flex">
            <div class="ms-auto">
              <div class="dropdown">
                <button
                  id="navbarDropdownMenuLink"
                  class="btn btn-link text-secondary ps-0 pe-2"
                  :class="{ show: showMenu }"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click="showMenu = !showMenu"
                >
                  <i class="text-lg fa fa-ellipsis-v"></i>
                </button>
                
                <div
                  class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3"
                  :class="{ show: showMenu }"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <a
                    v-for="(drop, index) in dropdown"
                    :key="index"
                    class="dropdown-item border-radius-md"
                    :href="drop.route"
                    >{{ drop.label }}
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>
.strong {
  font-weight: bold;
}
</style>
<script>

export default {
  name: "ComplexProjectCard",
  props: {
    logo: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    dateTime: {
      type: String,
      default: "",
    },
    dropdown: {
      type: Array,
      default: () => [],
      label: String,
      route: String,
    },
    tap: null,
    beer: null,
    beer_manufacturer: null,
    partner_name: null,
  },
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>
