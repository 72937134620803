<template>
  <div class="card mx-4 mt-4 p-4">
    <form method="POST" id="bank_settings_form" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-sm-12 mb-2">
          Razão social<br>
          <input type="text" class="form-control" name="PartnerBankAccountForm[name]" :value="dados['name']">
        </div>
        <div class="col-sm-12 mb-2">
          CNPJ<br>
          <input type="tel" v-mask="'##.###.###/####-##'" class="form-control" name="PartnerBankAccountForm[document]" :value="dados['document']">
        </div>
        <div class="col-sm-6 mb-2">
          Banco<br>
          <select name="PartnerBankAccountForm[bank_id]" ref="bancos" class="form-control">
            <option v-for="(value, key) in bancos" :key="key" :value="key">{{ value }}</option>
          </select>
        </div>
        <div class="col-sm-6 mb-2">
          Tipo<br>
          <select name="PartnerBankAccountForm[type]" ref="tipos" class="form-control">
            <option v-for="(value, key) in tipos" :key="key" :value="key">{{ value }}</option>
          </select>
        </div>
        <div class="col-sm-6 mb-2">
          Agência<br>
          <input type="text" class="form-control" name="PartnerBankAccountForm[agency]" :value="dados['agency']">
        </div>
        <div class="col-sm-6 mb-2">
          Conta<br>
          <input type="text" class="form-control" name="PartnerBankAccountForm[account]" :value="dados['account']">
        </div>
      </div>
    
      <br>
      <div class="row justify-content-center">
        <div class="col-md-12">
          <button type="submit" class="btn w-100 btn-success btn-irispay">Salvar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import api from '@/utils/api.js';
import {mask} from 'vue-the-mask'
/* eslint-disable vue/no-unused-components */
export default {
  name: "BankSettings",
  data() {
    return {
      showMenu: false,
      dados: [],
      bancos: [],
      tipos: [],
    };
  },
  components: {

  },
  directives: {mask},
  methods:{
    submitForm: function(){
      //panel-partner/beer/create
      let form = document.getElementById("bank_settings_form");
      if(!form){ return; }
      var formData = new FormData(form);

      this.$swal.showLoading();
      api
        .post('/dashboard/bank-settings', formData)
        .then((response) => {

          this.$swal.close();

          console.log("retorno api");
          console.log(response.data);

          if(response.data.type == 'danger')
          {
            this.$swal({
              title: 'Falha ao salvar',
              text: response.data.message,
              icon: "error",
              toast: true,
              position: "bottom-end",
              timer: 4000,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
          else
          {
            this.$swal({
              title: 'Dados atualizados!',
              text: response.data.message,
              icon: "success",
              toast: true,
              position: "bottom-end",
              timer: 4000,
              timerProgressBar: true,
              showConfirmButton: false,
            });

            this.$router.push({ name: 'BankSettings' });

          }


          
        })
        .catch((error) => {
          if(error.response){
            console.log(error.response);
            this.$swal({
              title: error.response.data.message,
              text: error.response.data.error,
              icon: "error",
              toast: true,
              position: "bottom-end",
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }); 
    },
  },
  watch: {

  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;

    this.$swal.showLoading();

    api
      .get('/dashboard/get-bank-settings')
      .then((response) => {
        this.$swal.close();
        this.dados = response.data.data;
        this.bancos = response.data.banks;
        this.tipos = response.data.types;
        console.log(response.data);
      })
      .catch((error) => {
        console.log("erro: ");
        console.log(error);
      }); 

  },
  updated(){

  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
};
</script>