<template>

<div class="position-fixed mt-2 top-10 end-1 z-index-1">
  <argon-snackbar
    v-if="snackbar"
    :title="snackbar.title"
    :description="snackbar.message"
    :icon="{ component: 'ni ni-spaceship', color: 'white' }"
    :color="snackbar.color"
    :close-handler="closeSnackbar"
  />
</div>

  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Editar PDV</h5>
              </div>
            </div>
          </div>
          <div class="px-4 pb-3 card-body">

            <PDVForm 
              v-if="pdvData"
              @savePDV="pdvEdit"
              :form="pdvData"
            />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/utils/api.js";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import PDVForm from "./components/PDVform.vue"

export default {
  name: "PDVList",
  components: {
    ArgonSnackbar,
    PDVForm,
  },
  data(){
    return {
      pdvData: null,
      snackbar: null,
    }
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
  beforeCreate() {
      this.$swal.showLoading()
      api
        .get('/dashboard/get-pdv-data?id=' + this.$route.params.id)
        .then((response) => {
          this.$swal.close()
          this.pdvData = response.data;
        })
        .catch((error) => {
            console.log("erro ao consultar dados do pdv: ");
            console.log(error);
        });
  },
  methods: {
    pdvEdit(formData){
      this.$swal.showLoading()
      api
        .post('/dashboard/update-pdv?id=' + this.$route.params.id, formData)
        .then((response) => {
          this.$swal.close()
            
            if(response.data.status == 'error'){
              let error = Object.keys(response.data.errors)[0];

              this.$swal({
                title: 'Erro ao salvar PDV',
                text: response.data.errors[error],
                icon: "error",
                toast: true,
                position: "bottom-end",
                timer: 4000,
                timerProgressBar: true,
                showConfirmButton: false,
              });

            } else {

              this.$swal({
                title: 'Sucesso!',
                text: 'PDV salvo com sucesso!',
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 4000,
                timerProgressBar: true,
                showConfirmButton: false,
              });

              this.$router.push({name: 'pdv-list'});
            }
        })
        .catch((error) => {
          this.$swal.close()
            console.log("erro ao atualizar pdv: ");
            console.log(error);
        });
    },
    closeSnackbar(){
      this.snackbar = null;
    },
  }



};
</script>
