<template>
  <div class="card position-sticky top-1 mt-4">
    <ul class="nav flex-column bg-white border-radius-lg p-3">
      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#profile"
        >
          <i class="ni ni-spaceship me-2 text-dark opacity-6"></i>
          <span class="text-sm">Profile</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#basic-info"
        >
          <i class="ni ni-books me-2 text-dark opacity-6"></i>
          <span class="text-sm">Basic Info</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#password"
        >
          <i class="ni ni-atom me-2 text-dark opacity-6"></i>
          <span class="text-sm">Change Password</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#2fa"
        >
          <i class="ni ni-ui-04 me-2 text-dark opacity-6"></i>
          <span class="text-sm">2FA</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#accounts"
        >
          <i class="ni ni-badge me-2 text-dark opacity-6"></i>
          <span class="text-sm">Accounts</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#notifications"
        >
          <i class="ni ni-bell-55 me-2 text-dark opacity-6"></i>
          <span class="text-sm">Notifications</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#sessions"
        >
          <i class="ni ni-watch-time me-2 text-dark opacity-6"></i>
          <span class="text-sm">Sessions</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#delete"
        >
          <i class="ni ni-settings-gear-65 me-2 text-dark opacity-6"></i>
          <span class="text-sm">Delete Account</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SideNav",
};
</script>
