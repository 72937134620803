<template>
    <form @submit.prevent="saveEmployee">
        <div class="row mb-2">
            <div class="col-12 col-sm-6">

                <label>Nome</label>
                <input class="form-control" type="text" placeholder="Nome" v-model="formMutable.name" />
            </div>

            <div class="col-12 col-sm-6">
                <label>Email</label>
                <input 
                class="form-control" 
                type="email" 
                placeholder="Email"
                v-model="formMutable.email" />
            </div>

        </div>

        <div class="row mb-2">
            <div class="col-12 col-sm-6">
                <label>Senha</label>
                <input class="form-control" type="password" placeholder="Senha" v-model="formMutable.password" />
            </div>
            <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Verificar senha</label>
                <input class="form-control" type="password" placeholder="Verificar senha" v-model="formMutable.passwordVerify" />
            </div>
        </div>

        <div class="row mb-2">
            <div class="col">
                <label>Gênero</label>
                <select
                class="form-control"
                v-model="formMutable.gender"
                >
                    <option 
                    v-for="gender, index in gender_list"
                    :key="index"
                    :value="index"
                    >
                        {{gender}}
                    </option>
                </select>
            </div>

            <div class="col">
                <label>Nascimento</label>
                <input 
                class="form-control" 
                type="date" 
                v-model="formMutable.birthday"/>
            </div>

        </div>

        <div class="row mb-2">
            <div class="col">
                <div class="form-check">
                    <input 
                        class="form-check-input" 
                        type="checkbox" 
                        id="allow_credit" 
                        v-model="formMutable.allow_credit"
                    >
                    <label class="form-check-label" for="allow_credit">
                        Adicionar créditos
                    </label>
                </div>
            </div>
        </div>

        <div class="row mb-2 mt-3">
            <argon-button 
            class="form-control btn-irispay"
            color="success" 
            size="md" 
            variant="gradient"
            type="submit"
            >
            Salvar
            </argon-button>
        </div>
    </form>
</template>

<script>
import api from "@/utils/api.js";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
    components: {
        ArgonButton,
    },
    emits: ["saveEmployee"],

    props: {
        form: {
            type: Object,
            default() {
                return {
                    name: "",
                    email: "",
                    password: "",
                    passwordVerify: "",
                    allow_credit: false,
                    gender: 2,
                    birthday: null,
                }
            }
        },
    },
    data() {
        return {
            formMutable: this.form,
            gender_list: null,
        }
    },
    mounted(){
        console.log("Mounted");

        api
            .get('/user/get-gender')
            .then((response) => {
                this.gender_list = response.data;
            })
            .catch((error) => {
                console.log("erro ao obter gender_list: ");
                console.log(error);
            });
    },
    methods: {
        saveEmployee(){

            if(this.formMutable.passwordVerify == ""){
                this.formMutable.passwordVerify = false;
            } 

            this.formMutable.allow_credit = this.formMutable.allow_credit ? 1 : 0;

            this.$emit("saveEmployee", this.formMutable);

            this.formMutable.passwordVerify = "";
        },

    }
}
</script>