<template>
  <div class="card mx-4 mt-4 p-4">
    <form action="POST" id="create_product_form">
      <div class="row">
        <div class="col-md-4 mb-1 text-center">
          Imagem<br>
          <img style="max-width:100%; min-height: 100px;" :src="logoUrl" alt="TESTE"/>
        </div>
        <div class="col-md-8 mb-1">
          Produto<br>
          <select name="product_id" id="product" ref="product" v-on:change="getInfo($event)" class="form-control">
            <option v-for="product in products" :key="product.id" :value="product.id">{{ product.name }}</option>
          </select>
        </div>
        <div class="col-md-6 mb-1">
          Nome<br>
          <input type="text" class="form-control" :value="nome" readonly>
        </div>
        <div class="col-md-6 mb-1">
          Categoria<br>
          <input type="text" class="form-control" :value="categoria" readonly>
        </div>

        <div class="col-md-6 mb-1">
          Marca<br>
          <input type="text" class="form-control" :value="marca" readonly>
        </div>

        <div class="col-md-6 mb-1">
          Preço<br>
          <input type="number" class="form-control" name="value">
        </div>

        <div class="col-md-12 mb-1">
          Descrição<br>
          <textarea rows="3" name="description" :value="descricao" readonly class="form-control"></textarea>
        </div>

        <div class="col-md-12 mt-4">
          <button type="button" @click="submitForm()" class="btn w-100 btn-success btn-irispay">Adicionar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import api from '@/utils/api.js';

export default {
  name: "ProductCreate",
  data() {
    return {
      showMenu: false, 
      logoUrl: "https://partner.rtn.com.br/images/iris-hor.png",
      nome: "",
      categoria: "",
      marca: "",
      descricao: "",
      products: [],
    };
  },
  methods:{
    getInfo: function(e){
      var id = e.target.value;
      this.getProductInfo(id);
    },
    getProductInfo: function(id){
      this.$swal.showLoading();
      api
        .get("product-partner/list?expand=category&id="+id)
        .then((response) => {
          this.$swal.close();

          this.logoUrl = 'http://assets.rtn.com.br/uploads/product/image/thumbs/small/'+response.data.image;
          this.nome = response.data.name;
          this.categoria = response.data.category.name;
          this.marca = response.data.brand;
          this.descricao = response.data.description;
        })
        .catch((error) => {
          console.log("erro: ");
          console.log(error);
        }); 
    },
    submitForm: function(){
      //panel-partner/beer/create
      let form = document.getElementById("create_product_form");
      if(!form){ return; }
      var formData = new FormData(form);

      this.$swal.showLoading();
      api
        .post('/product-partner/create', formData)
        .then(() => {
          this.$swal.close();
          this.$router.push({ name: 'Product' });
        })
        .catch((error) => {
          if(error.response){
            console.log(error.response);
            this.$swal({
              title: error.response.data.message,
              text: error.response.data.error,
              icon: "error",
              toast: true,
              position: "bottom-end",
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }); 
    }
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;

    this.$swal.showLoading();
    api
      .get('/product-partner/list')
      .then((response) => {
        this.$swal.close();

        this.products = response.data;
        this.getProductInfo(this.products[0].id);
      })
      .catch((error) => {
        console.log("erro: ");
        console.log(error);
      }); 
  },
  updated(){

  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
};
</script>