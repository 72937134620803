<template>
  <div class="card mx-4 mt-4 p-4">
    <form action="POST" id="create_user_api_form" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-md-12 mb-1">
          Email<br>
          <input required type="email" name="UserApiForm[email]" class="form-control">
        </div>
        <div class="col-md-12 mb-1">
          Status&nbsp;
          <label class="switch">
            <input value="1" type="checkbox" name="UserApiForm[status]">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="col-md-12 mt-4">
          <button type="submit" class="btn w-100 btn-irispay">Salvar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import api from '@/utils/api.js';

/* eslint-disable vue/no-unused-components */
export default {
  name: "UserApiCreate",
  data() {
    return {
      showMenu: false,
    };
  },
  methods:{
    submitForm: function(){
      //panel-partner/beer/create
      let form = document.getElementById("create_user_api_form");
      if(!form){ return; }
      var formData = new FormData(form);
      if(!formData.has("UserApiForm[status]"))
      {
        formData.append("UserApiForm[status]", 0);
      }

      this.$swal.showLoading();
      api
        .post('/user-api/create', formData)
        .then((response) => {
          this.$swal.close();

          console.log("retorno api");
          console.log(response.data);

          if(response.data.type == 'danger')
          {
            this.$swal({
              title: 'Erro!',
              text: response.data.message,
              icon: "error",
              toast: true,
              position: "bottom-end",
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
          else{
            this.$swal({
              title: 'Sucesso!',
              text: response.data.message,
              icon: "success",
              toast: true,
              position: "bottom-end",
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
            this.$router.push({ name: 'UserApi' });
          }

        })
        .catch((error) => {
          if(error.response){
            console.log(error.response);
            this.$swal({
              title: error.response.data.message,
              text: error.response.data.error,
              icon: "error",
              toast: true,
              position: "bottom-end",
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }); 
    },
  },
  watch: {

  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;
    this.$swal.close();
  },
  updated(){

  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
};
</script>