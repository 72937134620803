<template>
  <div
    id="sidenav-collapse-main"
    style="overflow-x: hidden;"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav" v-if="user">

      <li class="nav-item" v-if="user.type == 'partner' || user.type == 'employee'">
        <sidenav-collapse-awesome
          nav-text="Torneiras"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref="/beertap"
        >
          <template #icon>
            <!--<i
              class="ni ni-align-left-2 text-sm"
              :class="
                $store.state.sidebarType === 'bg-default' ||
                $store.state.darkMode
                  ? 'text-white'
                  : 'text-dark'
              "
            ></i>-->
            <i class="fas text-irispay fa-fw fa-edit"></i>
          </template>
        </sidenav-collapse-awesome>
      </li>

      <li class="nav-item" v-if="user.type == 'partner'">
        <sidenav-collapse-awesome
          nav-text="PDVs"
          collapse-ref="pdvs"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="fas text-irispay fa-fw fa-receipt"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item
                :to="{ name: 'pdv-list' }"
                mini-icon="L"
                text="Lista de PDVs"
              />
              <sidenav-item
                :to="{ name: 'pdv-create' }"
                mini-icon="L"
                text="Adicionar PDV"
              />
            </ul>
          </template>
        </sidenav-collapse-awesome>
      </li>

      <li class="nav-item" v-if="user.type == 'partner'">
        <sidenav-collapse-awesome
          nav-text="Bebidas"
          collapse-ref="bebidas"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="fas text-irispay fa-fw fa-coffee"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item
                :to="{ name: 'Beer' }"
                mini-icon="&#x2023;"
                text="Lista de Bebidas"
              />
              <sidenav-item
                :to="{ name: 'BeerCreate' }"
                mini-icon="&#x2023;"
                text="Adicionar bebida"
              />
            </ul>
          </template>
        </sidenav-collapse-awesome>
      </li>

      <li class="nav-item" v-if="user.type == 'partner'">
        <sidenav-collapse-awesome
          nav-text="Produtos"
          collapse-ref="produtos"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="fas fa-fw fa-archive text-irispay"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item
                :to="{ name: 'Product' }"
                mini-icon="&#x2023;"
                text="Lista de Produtos"
              />
              <sidenav-item
                :to="{ name: 'ProductCreate' }"
                mini-icon="&#x2023;"
                text="Adicionar produto"
              />
            </ul>
          </template>
        </sidenav-collapse-awesome>
      </li>

      <li class="nav-item" v-if="user.type == 'partner'">
        <router-link :to="{ name: 'Account' }" class="nav-link">
          <i class="fas fa-fw fa-house-user text-irispay"></i>
          <span class="nav-link-text ms-1">Meus dados</span>
        </router-link>
        <div class="collapse"></div>
      </li>

      <li class="nav-item" v-if="user.type == 'partner'">
        <router-link :to="{ name: 'BankSettings' }" class="nav-link">
          <i class="fas fa-fw fa-file-invoice-dollar text-irispay"></i>
          <span class="nav-link-text ms-1">Dados bancarios</span>
        </router-link>
        <div class="collapse"></div>
      </li>

      <li class="nav-item" v-if="user.type == 'partner'">
        <router-link :to="{ name: 'UserApi' }" class="nav-link">
          <i class="fas fa-fw fa-th-large text-irispay"></i>
          <span class="nav-link-text ms-1">API</span>
        </router-link>
        <div class="collapse"></div>
      </li>

      <li class="nav-item" v-if="user.type == 'partner' || user.type == 'employee' && user.allow_credit == 1">
        <sidenav-collapse-awesome
          nav-text="Créditos"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref="/user-credit-entry"
        >
          <template #icon>
            <!--
            <i
              class="ni ni-align-left-2 text-sm"
              :class="
                $store.state.sidebarType === 'bg-default' ||
                $store.state.darkMode
                  ? 'text-white'
                  : 'text-dark'
              "
            ></i>
            -->
            <i class="fas fa-fw fa-money-bill text-irispay"></i>
          </template>
        </sidenav-collapse-awesome>
      </li>

      <li class="nav-item" v-if="user.type == 'partner'">
        <sidenav-collapse-awesome
          nav-text="Funcionários"
          collapse-ref="funcionários"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="fas fa-fw fa-portrait text-irispay"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item
                :to="{ name: 'employees-list' }"
                mini-icon="L"
                text="Lista de Funcionários"
              />
              <sidenav-item
                :to="{ name: 'employees-create' }"
                mini-icon="L"
                text="Adicionar funcionário"
              />
            </ul>
          </template>
        </sidenav-collapse-awesome>
      </li>

      <li class="nav-item" v-if="user.type == 'partner' || user.type == 'pdv'">
        <sidenav-collapse-awesome
          nav-text="Vendas"
          collapse-ref="vendas"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="fas text-irispay fa-fw fa-dollar-sign "></i>
          </template>

          <template #list>
            <ul class="nav ms-4">
              <sidenav-item
                :to="{ name: 'beer-out' }"
                mini-icon="L"
                text="Vendas"
              />
              <sidenav-item v-if="user.type != 'pdv'"
                :to="{ name: 'report-withdraw' }"
                mini-icon="L"
                text="Vendas (funcionário)"
              />
            </ul>
          </template>
        </sidenav-collapse-awesome>
      </li>
      <li class="nav-item" v-if="user.type == 'partner' || user.type == 'pdv'">
        <sidenav-collapse-awesome
          nav-text="Dashboard de Vendas"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref="/dashboard/dashboard-bi"
        >
          <template #icon> 
            <i
              class="fas text-irispay fa-fw fa-chart-bar"
            ></i>
          </template>
        </sidenav-collapse-awesome>
      </li>

      <li class="nav-item" v-if="user.type == 'partner' || user.type == 'pdv'" @click="clickReports()">
        <sidenav-collapse-awesome
          nav-text="Relatório de Vendas"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref="#"
        >
          <template #icon> 
            <i
              class="fas text-irispay fa-fw fa-chart-line"
            ></i>
          </template>
        </sidenav-collapse-awesome>
      </li>

      <li class="nav-item" v-if="user.type == 'partner' || user.type == 'pdv'"  @click="clickHeatMap()">
        <sidenav-collapse-awesome
          nav-text="Mapa de calor"
          :collapse="false"
          url="#"
          :aria-controls="''"
          collapse-ref="#"
        >
          <template #icon> 
            <i
              class="fas text-irispay fa-fw fa-chart-line"
            ></i>
          </template>
        </sidenav-collapse-awesome>
      </li>

    </ul>
  </div>

</template>
<script>
import api from '@/utils/api.js';
import SidenavItem from "./SidenavItem.vue";
//import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCollapseAwesome from "./SidenavCollapseAwesome.vue";
//import SidenavCard from "./SidenavCard.vue";
//import SidenavCollapseItem from "./SidenavCollapseItem.vue";

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
//    SidenavCollapse,
    SidenavCollapseAwesome,
//    SidenavCard,
//    SidenavCollapseItem
  },
  data(){
    return {
      user: null,
    }
  },
  beforeMount(){
    const user = JSON.parse(localStorage.getItem('user'));
    this.user = user;
    console.log("user:");
    console.log(user);
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    clickHeatMap(){
      this.$swal.showLoading();
      api
        .get("/dashboard/heat-map")
        .then((response) => {
            console.log(response.data);

            this.$swal.close();

            window.open(response.data.url, "_blank");
        })
        .catch((error) => {
          this.$swal.close();
          console.log("erro: ");
          console.log(error);
        }); 
    },
    clickReports(){
      this.$swal.showLoading();
      api
        .get("/dashboard/reports-bi")
        .then((response) => {
            console.log(response.data);

            this.$swal.close();

            window.open(response.data.url, "_blank");
        })
        .catch((error) => {
          this.$swal.close();
          console.log("erro: ");
          console.log(error);
        }); 
    },

  }
};
</script>
