<template>
  <div class="card mx-4 mt-4 p-4">
    <form action="POST" id="create_beer_form" @submit.prevent="submitForm">

      <input type="hidden" name="beer_id" class="form-control" :value="beer_id">
      
      <div class="row">
        <div class="col-md-4 mb-1 text-center">
          Logo<br>
          <img style="max-width:100%; min-height: 100px;" :src="logoUrl" alt="TESTE"/>
        </div>
        <div class="col-md-4 mb-1">
          Fabricante<br>
          <select name="manufacturer_id" id="manufacturer" ref="manufacturer" v-on:change="getNames($event)" class="form-control">
            <option v-for="manufacturer in manufacturers" :key="manufacturer.id" :value="manufacturer.id">{{ manufacturer.title }}</option>
          </select>
        </div>
        <div class="col-md-4 mb-1">
          Nome<br>
          <select name="beer_manufacturer_id" ref="beermanufacturer_name" v-on:change="changeBeer($event)" class="form-control">
            <option v-for="beer_manufacturer in beer_manufacturers" :key="beer_manufacturer.id" :value="beer_manufacturer.id">{{ beer_manufacturer.name }}</option>
          </select>
        </div>

        <div class="col-md-6 mb-1">
          Tipo<br>
          <input type="text" class="form-control" :value="tipo" readonly>
        </div>
        <div class="col-md-6 mb-1">
          Coloração<br>
          <input type="text" class="form-control" :value="coloracao" readonly>
        </div>

        <div class="col-md-6 mb-1">
          Origem<br>
          <input type="text" class="form-control" :value="origem" readonly>
        </div>

        <div class="col-md-6 mb-1">
          Preço por litro (obrigatório)<br>
          <input type="number" class="form-control" :value="price" name="price" required>
        </div>

        <div class="col-md-6 mb-1">
          IBU<br>
          <input type="text" class="form-control" :value="ibu" readonly>
        </div>
        <div class="col-md-6 mb-1">
          Percentual alcoólico<br>
          <input type="text" class="form-control" :value="percentual" readonly>
        </div>

        <div class="col-md-12 mb-1">
          Descrição<br>
          <textarea name="description" class="form-control"></textarea>
        </div>

        <div class="col-md-12 mt-4">
          <button type="submit" class="btn w-100 btn-success btn-irispay">Salvar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import api from '@/utils/api.js';

export default {
  name: "BeerCreate",
  data() {
    return {
      showMenu: false,
      logoUrl: "http://partner.rtn.com.br/images/iris-vert.png",
      tipo: "",
      coloracao: "",
      origem: "",
      ibu: "",
      percentual: "",
      price: "",
      beer_id:"",

      manufacturers: [],
      beer_manufacturers: [],
    };
  },
  methods:{
    getNames: function(e){
      var id = e.target.value;
      this.getBeers(id);
    },
    getBeers: function(id){
      this.beer_manufacturers = [];

      this.$swal.showLoading();
      api
        .get("beer/beermanufacturer-names?expand=beerType,beerColor&manufacturer_id="+id)
        .then((response) => {
          this.$swal.close();
          this.beer_manufacturers = response.data;
          console.log(response.data);
          this.getBeerData(this.beer_manufacturers[0].id);
        })
        .catch((error) => {
          console.log("erro: ");
          console.log(error);
        }); 
    },
    submitForm: function(){
      //panel-partner/beer/create
      let form = document.getElementById("create_beer_form");
      if(!form){ return; }
      var formData = new FormData(form);


      this.$swal.showLoading();
      api
        .post('/beer/create', formData)
        .then((response) => {

          console.log(response.data);

          this.$swal.close();
          this.$router.push({ name: 'Beer' });
        })
        .catch((error) => {
          if(error.response){
            console.log(error.response);
            this.$swal({
              title: error.response.data.message,
              text: error.response.data.error,
              icon: "error",
              toast: true,
              position: "bottom-end",
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }); 
    },
    changeBeer: function(e){
      var id = e.target.value;
      this.getBeerData(id);
    },
    getBeerData: function(id){
      for(let i = 0; i < this.beer_manufacturers.length; i++)
      {
        if(this.beer_manufacturers[i].id == id)
        {
          this.tipo = this.beer_manufacturers[i].beerType.name;
          this.coloracao = this.beer_manufacturers[i].beerColor.name;
          this.origem = this.beer_manufacturers[i].origin;
          this.ibu = this.beer_manufacturers[i].ibu;
          this.percentual = this.beer_manufacturers[i].alcoholic_strength;
          this.beer_id = this.beer_manufacturers[i].id;

          if(this.beer_manufacturers[i].logo.length > 0)
          {
            this.logoUrl = "http://assets.rtn.com.br/uploads/beermanufacturer/logo/thumbs/small/"+this.beer_manufacturers[i].logo;
          }else{
            this.logoUrl = "http://partner.rtn.com.br/images/iris-vert.png";
          }
          break;
        }
      }
    }
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;

    this.$swal.showLoading();
    api
      .get('/beer/manufacturers')
      .then((response) => {
        this.$swal.close();

        this.manufacturers = response.data;
        this.getBeers(this.manufacturers[0].id);
      })
      .catch((error) => {
        console.log("erro: ");
        console.log(error);
      }); 
  },
  updated(){

  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
};
</script>