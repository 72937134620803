<template>
  <div
    v-if="layout === 'custom'"
    class="position-absolute w-100 min-height-300 top-0"
  >
    <span class="mask opacity-10 bg-irispay"></span>
  </div>
  <div
    v-show="layout === 'default'"
    class="min-height-300 position-absolute w-100"
    :class="`${darkMode ? 'bg-transparent' : 'bg-success'}`"
  />

  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-4"
    :class="` ${sidebarType} ${
      isRTL ? 'fixed-end me-3 rotate-caret' : 'fixed-start ms-3'
    } ${layout === 'landing' ? 'bg-transparent shadow-none' : ''}`"
  >
    <hr class="mt-0 horizontal dark" />
    <sidenav-list />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";


import { mapState } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList
  },
  data() {
    return {
      logo: '',
      logoWhite: '',
      image: '',
    };
  },
  computed: {
    ...mapState(["layout", "isRTL", "sidebarType", "darkMode"])
  },
  beforeUnmount() {
    this.$store.state.image;
  }
};
</script>
