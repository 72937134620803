<template>
    <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Editar torneira</h5>
              </div>
            </div>
          </div>
          <div class="px-4 pb-3 card-body">

            <form @submit.prevent="save">

                <div class="row">
                    <div class="col">
                        <label>Código</label>
                        <input 
                        class="form-control" 
                        type="number" 
                        placeholder="Código"
                        v-model="form.id"
                        disabled
                        />
                    </div>

                    <div class="col">
                        <label>MAC</label>
                        <input 
                        class="form-control" 
                        type="text" 
                        placeholder="MAC"
                        v-model="form.mac"
                        disabled
                        />
                    </div>

                    <div class="col">
                        <label> </label>
                        <div class="form-check">
                            <input 
                                class="form-check-input" 
                                type="checkbox" 
                                id="status_beertap" 
                                v-model="form.status"
                            >
                            <label class="form-check-label" for="status_beertap">
                                Status
                            </label>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <label>Nome da torneira</label>
                        <input 
                        class="form-control" 
                        type="text" 
                        v-model="form.name"
                        />
                    </div>

                    <div class="col">
                        <label>Fabricantes</label>
                        <select
                            id="choices-state-edit"
                            class="form-control"
                            name="choices-manufacturers"
                            v-model="form.manufacturers"
                            @change="getBeerByManufacturer"
                        >

                            <option value="" disabled selected>Selecione um fabricante</option>
                            <option 
                            v-for="manufacturer, index in beerManufacturers"
                            :key="index"
                            :value="index"
                            >
                                {{manufacturer}}
                            </option>

                        </select>
                    </div>

                    <div class="col">
                        <label>Bebidas</label>
                        <select
                            id="choices-state-edit"
                            class="form-control"
                            name="choices-manufacturers"
                            v-model="form.beer_id"
                        >

                            <option value="" disabled>Selecione uma bebida</option>
                            <option 
                            v-for="beer, index in beerByManufacturer"
                            :key="index"
                            :value="index"
                            >
                                {{beer}}
                            </option>

                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <label>Capacidade (Litros)</label>
                        <input 
                        class="form-control" 
                        type="number" 
                        v-model="form.capacity"
                        required
                        />
                    </div>

                    <div class="col">
                        <label>Avisar quando o nível estiver em (Litros)</label>
                        <input 
                        class="form-control" 
                        type="text" 
                        v-model="form.warning"
                        required
                        />
                    </div>

                    <div class="col">
                        <label>Orientação</label>
                        <select
                            id="choices-state-edit"
                            class="form-control"
                            name="choices-manufacturers"
                            v-model="form.orientation"
                        >
                            <option value="" disabled selected>Selecione a orientação</option>
                            <option value="4">Vertical</option>
                            <option value="2">Horizontal</option>

                        </select>
                    </div>
                </div>

                <div class="row mb-2 mt-3">
                    <argon-button 
                    class="form-control btn-irispay"
                    color="success" 
                    size="md" 
                    variant="gradient"
                    >
                    Salvar
                    </argon-button>
                </div>

            </form>

            <div>
                <h5 class="mt-3">Histórico</h5>
            </div>

            <div class="table-responsive">
              <table id="taps" class="table table-flush">
                <thead class="thead-light">
                    <tr>
                        <th>ID</th>
                        <th>Torneira</th>
                        <th>Parceiro</th>
                        <th>Usuário</th>
                        <th>Fabricante</th>
                        <th>Bebida</th>
                        <th>Capacidade</th>
                        <th>Barril atualizado em</th>
                    </tr>
                </thead>
                <tbody>
                  <tr v-for="data in historic" :key="data.id">
                    <td class="text-sm">{{data.id}}</td>
                    <td class="text-sm">{{data.beertap_id}}</td>
                    <td class="text-sm" width="2%">{{data.partner_name}}</td>
                    <td class="text-sm" width="5%">{{data.user_name}}</td>
                    <td class="text-sm">{{data.manufacturer_name}}</td>
                    <td class="text-sm">{{data.beer_name}}</td>
                    <td class="text-sm">{{data.barrel_capacity}}</td>
                    <td class="text-sm" width="5%">{{data.barrel_update_date}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import api from "@/utils/api.js";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
    components: {
        ArgonButton,
    },
    data () {
        return {
            beerManufacturers: [],
            beerByManufacturer: [],
            historic: null,
            form: {
                id: null,
                mac: null,
                name: null,
                status: null,
                manufacturers: null,
                beer_id: null,
                capacity: null,
                warning: null,
                orientation: null,
            }
        }
    },
    beforeMount() {
        this.$store.state.layout = "custom";
        this.$store.state.showNavbar = true;
        this.$store.state.showFooter = false;
        this.$store.state.hideConfigButton = true;
        this.$store.state.showSidenav = true;
    },
    beforeUnmount() {
        this.$store.state.isAbsolute = false;
        this.$store.state.layout = "default";
        this.$store.state.showNavbar = true;
        this.$store.state.showFooter = true;
        this.$store.state.hideConfigButton = false;
    },
    mounted(){

        this.$swal.showLoading()

        api
            .get('/beer/get-manufacturers-by-partner/')
            .then((response) => {
                this.beerManufacturers = response.data;
                api
                    .get('/beertap/get-beertap-data?id=' + this.$route.params.id)
                    .then((response) => {

                        this.historic = response.data.historic

                        this.form.id = response.data.beertap.id
                        this.form.mac = response.data.beertap.mac
                        this.form.name = response.data.beertap.name
                        this.form.status = response.data.beertap.status ? true : false
                        this.form.manufacturers = response.data.beertap.beer.manufacturer_id
                        this.form.beer_id = response.data.beertap.beer_id
                        this.form.capacity = response.data.beertap.capacity
                        this.form.warning = response.data.beertap.warning
                        this.form.orientation = response.data.beertap.orientation

                        api
                            .get('/partner/get-beer-items')
                            .then((response2) => {

                                this.$swal.close()
                                this.beerByManufacturer = response2.data

                                console.log("beer items");
                                console.log(this.beerByManufacturer);
                            })
                            .catch((error) => {
                                this.$swal.close()
                                console.log("erro ao obter bebidas do fabricante especificado: ");
                                console.log(error);
                            });

                    })
                    .catch((error) => {
                        this.$swal.close()
                        console.log("erro ao obter dados da torneira: ");
                        console.log(error);
                    });          
            })
            .catch((error) => {
                console.log("erro ao obter dados de fabricantes: ");
                console.log(error);
            });

    },
    methods: {
        getBeerByManufacturer(){
            this.beerByManufacturer = [];
            api
                .post('/beertap/beers-manufacturer',{
                    id: this.form.manufacturers
                })
                .then((response) => {
                    this.beerByManufacturer = response.data
                })
                .catch((error) => {
                    console.log("erro ao obter bebidas do fabricante especificado: ");
                    console.log(error);
                });
        },
        save(){

            this.$swal.showLoading()

            console.log("Salvando torneira...");

            this.form.status = this.form.status ? 1 : 0

            api
                .post('/beertap/update?id=' + this.$route.params.id, this.form)
                .then((response) => {

                    this.$swal.close()

                    console.log("Torneira salva: ");
                    console.log(response.data);
                    this.form.status = this.form.status ? true : false

                    this.$router.push('/beertap')

                })
                .catch((error) => {
                    this.$swal.close()
                    console.log("erro ao editar torneira: ");
                    console.log(error);
                });

        }
    }

    
}
</script>