<template>
    <div>
        <iframe
            v-if="iframeUrl"
            :src="iframeUrl"
            frameborder="0"
            height="800"
            class="mt-2 py-4 container-fluid"
            allowtransparency
        ></iframe>
    </div>
</template>
<script>
import api from '@/utils/api.js';

export default {
    data(){
        return {
            iframeUrl: null,
        }
    },
    mounted(){
        this.$swal.showLoading()

    api
      .get('/dashboard/reports-bi')
      .then((response) => {

        this.iframeUrl = response.data.url;

        this.$swal.close()

        console.log("url: ");
        console.log(this.iframeUrl);
        console.log(response.data);

      })
      .catch((error) => {

        console.log("erro: ");
        console.log(error);

      }); 
    },
    beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
    
}
</script>