<template>
  <div class="card mx-4 mt-4 p-4">
    <form action="POST" id="edit_beer_form" @submit.prevent="submitForm">

      <input type="hidden" name="BeerForm[beer_id]" class="form-control" :value="id">

      <div class="row">
        <div class="col-md-4 mb-1 text-center">
          Logo<br>
          <img style="max-width:100%; min-height: 100px;" :src="logoUrl" alt="TESTE"/>
        </div>
        <div class="col-md-4 mb-1">
          Fabricante<br>
          <input type="text" class="form-control" :value="manufacturer_name" readonly>
          <input type="hidden" name="BeerForm[manufacturer_id]" :value="manufacturer_name_id" readonly>
        </div>
        <div class="col-md-4 mb-1">
          Nome<br>
          <input type="text" class="form-control" :value="beer_name" readonly>
          <input type="hidden" name="BeerForm[beer_manufacturer_id]" :value="beer_name_id" readonly>
        </div>

        <div class="col-md-6 mb-1">
          Tipo<br>
          <input type="text" class="form-control" :value="tipo" readonly>
        </div>
        <div class="col-md-3 mb-1">
          Coloração<br>
          <input type="text" class="form-control" :value="coloracao" readonly>
        </div>

        <div class="col-md-3 mb-1">
          Preço por litro<br>
          <input type="number" class="form-control" :value="price" name="BeerForm[price]" required>
        </div>

        <div class="col-md-6 mb-1">
          Origem<br>
          <input type="text" class="form-control" :value="origem" readonly>
        </div>

        <div class="col-md-3 mb-1">
          IBU<br>
          <input type="text" class="form-control" :value="ibu" readonly>
        </div>
        <div class="col-md-3 mb-1">
          Percentual alcoólico<br>
          <input type="text" class="form-control" :value="percentual" readonly>
        </div>

        <div class="col-md-12 mb-1">
          Descrição<br>
          <textarea required name="BeerForm[description]" :value="descricao" class="form-control"></textarea>
        </div>

        <div class="col-md-12 mt-4">
          <button type="submit" class="btn w-100 btn-success btn-irispay">Editar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import api from '@/utils/api.js';

export default {
  name: "BeerEdit",
  data() {
    return {
      showMenu: false,
      id: null,
      logo: "",
      logoUrl: "https://partner.rtn.com.br/images/iris-hor.png",
      tipo: "",
      tipo_id: "",

      coloracao: "",
      coloracao_id: "",

      origem: "",
      ibu: "",
      percentual: "",
      descricao: "",

      manufacturer_name: "",
      manufacturer_name_id: "",
      beer_name: "",
      beer_name_id: "",
      price: null,
    };
  },
  methods:{
    submitForm: function(){
      let form = document.getElementById("edit_beer_form");
      if(!form){ return; }
      var formData = new FormData(form);

      this.$swal.showLoading();
      api
        .post('/beer/update?id='+this.id, formData)
        .then(() => {
          this.$swal.close();
          //this.$router.push({ name: 'Beer', params: { edited: true} });
        })
        .catch((error) => {
          if(error.response){
            console.log(error.response);
            this.$swal({
              title: error.response.data.message,
              text: error.response.data.error,
              icon: "error",
              toast: true,
              position: "bottom-end",
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }); 
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;

    this.id = this.$route.params.id;
    if(!this.id){
      this.$router.push({ name: 'Beer' });
      return;
    }
    this.$swal.showLoading();
    api
      .get('beer/get-beer-data?expand=beerType,beerColor,manufacturer,beerManufacturer&id='+this.id)
      .then((response) => {
        this.$swal.close();
        console.log(response.data);

        this.beer_id = response.data.id;

        this.manufacturer_name = response.data.manufacturer.title;
        this.manufacturer_name_id = response.data.manufacturer.id;
        this.beer_name = response.data.beerManufacturer.name;
        this.beer_name_id = response.data.beerManufacturer.id;
        this.price = response.data.price;

        this.tipo = response.data.beerType.name;
        this.tipo_id = response.data.beerType.id;

        this.coloracao = response.data.beerColor.name;
        this.coloracao_id = response.data.beerColor.id;

        this.origem = response.data.origin;
        this.ibu = response.data.ibu;
        this.percentual = response.data.alcoholic_strength;
        this.descricao = response.data.description;

        this.logo = response.data.logo;

        if(response.data.logo.length > 0)
        {
          this.logoUrl = "http://assets.rtn.com.br/uploads/beermanufacturer/logo/thumbs/small/"+response.data.logo;
        }else{
          this.logoUrl = "http://partner.rtn.com.br/images/iris-vert.png";
        }
      })
      .catch((error) => {
        console.log("erro: ");
        console.log(error);
      }); 
  },
  updated(){

  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
};
</script>