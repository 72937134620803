/**
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.1
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);
appInstance.mount("#app");

appInstance.config.globalProperties.$filters = {
    toCurrency(value) {
        if (!value || value === null || value === '') 
        { 
            return 'R$ 0,00' 
        }
        if (typeof value === 'string') value = parseFloat(value)
    
        let numero = value.toFixed(2).split('.')
        numero[0] = 'R$ ' + numero[0].split(/(?=(?:...)*$)/).join('.')
        return numero.join(',')
    }
}
  
