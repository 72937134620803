<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">PDVs</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">

                  <router-link class="mb-0 btn bg-gradient-success btn-sm btn-irispay" :to="{ name: 'pdv-create'}">
                    +&nbsp; Criar novo PDV
                  </router-link>

                  <!-- <a
                    href="/pdv/create"
                    class="mb-0 btn bg-gradient-success btn-sm btn-irispay"
                    >+&nbsp; Criar novo PDV</a
                  > -->
                </div>
              </div>
            </div>
          </div>
          <div v-if="pdvs"  class="px-4 pb-2 card-body">
            <div class="table-responsive">
              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Codigo</th>
                    <th>Nome</th>
                    <th>Email</th>
                    <th width="5%"></th>
                  </tr>
                </thead>

                <tbody>

                  <tr v-for="pdv in pdvs" :key="pdv.id">
                    <td class="text-sm">{{pdv.id}}</td>
                    <td class="text-sm">{{pdv.name}}</td>
                    <td class="text-sm">{{pdv.email}}</td>
                    <td class="text-sm">

                      <router-link class="mx-3" :to="{ name: 'pdv-edit', params: { id: pdv.id } }">
                        <i class="fas fa-edit text-secondary"></i>
                      </router-link>

                      <a
                        href="javascript:;"
                        @click="deletepdv(pdv.id)"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Excluir PDV"
                      >
                        <i class="fas fa-trash text-secondary"></i>
                      </a>
                      
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/utils/api.js";

export default {
  name: "PDVList",
  data(){
    return {
      pdvs: false,
    }
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },

  mounted() {

    this.$swal.showLoading()

    api
      .get('/dashboard/get-pdv')
      .then((response) => {

        this.$swal.close()

        this.pdvs = response.data;
        
        console.log("PDVs retornados: ");
        console.log(this.pdvs);


      })
      .catch((error) => {

        this.$swal.close()

        console.log("erro ao obter PDVS: ");
        console.log(error);

      });
    
  },
  methods: {
    deletepdv(id){
      console.log("deletar pdv", id);

      this.$swal({
          title: "Tem certeza que deseja excluir o PDV?",
          text: "Não será possível reverter essa ação.",
          showCancelButton: true,
          confirmButtonText: "Sim, quero excluir! ",
          cancelButtonText: "Não, cancelar!",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {

            this.$swal({
              title: "Aguarde enquanto o seu PDV é deletado...",
              timerProgressBar: true,
            });

            api
              .get('/dashboard/delete-pdv?id=' + id)
              .then(() => {
                this.$swal.close()
                this.$router.go()
              })
              .catch((error) => {
                this.$swal.close()
                console.log("erro ao obter PDVS: ");
                console.log(error);
              });

          } else if (
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal({
              title: "Cancelado!",
              text: "O seu PDV não foi deletado!",
            //  timer: 2000,
              icon: "error",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
          }
        });

      

    }
  }



};
</script>
