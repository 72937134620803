<template v-if="beers != null">
  <div class="card mx-4 mt-4">
    <div class="table-responsive">
      <table class="table table-flush">
        <thead class="thead-light">
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Código<br>
            <input data-filter-field="true" name="BeerSearch[id]" v-on:keyup="checkSearchInput" type="text" class="form-control"></th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Fabricante<br>
            <input data-filter-field="true" name="BeerSearch[manufacturer_name]" v-on:keyup="checkSearchInput" type="text" class="form-control"></th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nome<br>
            <input data-filter-field="true" name="BeerSearch[name]" v-on:keyup="checkSearchInput" type="text" class="form-control"></th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Preço por litro<br>
            <input data-filter-field="true" name="BeerSearch[price]" v-on:keyup="checkSearchInput" type="text" class="form-control"></th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Origem<br>
            <input data-filter-field="true" name="BeerSearch[origin]" v-on:keyup="checkSearchInput" type="text" class="form-control"></th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="beer in beers"  :key="beer.id">
            <td>{{ beer.id }}</td>
            <td>{{ beer.manufacturer.title }}</td>
            <td>{{ beer.name }}</td>
            <td>
              <span v-if="beer.price">{{ $filters.toCurrency(beer.price) }}</span>
              <span v-else>(não definido)</span>
            </td>
            <td>{{ beer.origin }}</td>
            <td>
              <router-link :to="{ name: 'BeerEdit', params: { id: beer.id } }"><i class="fas fa-fw fa-pen"></i></router-link>
              <a href="#" @click="deleteRecord(beer.id)"><i class="fas fa-fw fa-trash-alt"></i></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import api from '@/utils/api.js';

export default {
  name: "Beer",
  data() {
    return {
      showMenu: false,
      beers:null,
    };
  },
  methods:{
    deleteRecord: function(id){
      this.$swal({
        title: "Tem certeza?",
        text: "Essa ação não pode ser revertida",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir.",
        cancelButtonText: "Não, cancelar.",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .get(`/beer/delete?id=${id}`)
            .then(() => {
              this.beers = this.beers.filter(function( obj ) {
                return obj.id !== id;
              });

              this.$swal({
                title: "Excluido!",
                text: "Registro excluido com sucesso.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 2500,
                timerProgressBar: true,
                showConfirmButton: false,
              });
            })
            .catch((error) => {
              console.log("erro: ");
              console.log(error);
            }); 
        }
      });
    },
    checkSearchInput: function (e) {
      if(e.key == "Enter")
      {
        this.doSearch();
      }
    },
    doSearch: function () {
      let final_url = '/dashboard/get-beer?expand=manufacturer';
      let fields = document.querySelectorAll('[data-filter-field="true"]')
      fields.forEach(field => {
        if(field.value.length > 0){
        final_url += `&${field.name}=${field.value}`;
        }
      });

      this.$swal.showLoading();

      api
        .get(final_url)
        .then((response) => {
          this.$swal.close();
          this.beers = response.data;
        })
        .catch((error) => {
          console.log("erro: ");
          console.log(error);
        }); 
    }
  },
  watch: {
    beers () { // data used in the table
     
    }
  },
  mounted() {
    this.$store.state.isAbsolute = true;

    setNavPills();
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;

    this.$swal.showLoading();

    api
      .get('/dashboard/get-beer?expand=manufacturer')
      .then((response) => {
        this.$swal.close();
        this.beers = response.data;
        console.log(response.data);

        if(this.$route.params.edited){
          this.$swal({
            title: "Bebida alterada!",
            text: "Bebida alterada com sucesso.",
            icon: "success",
            toast: true,
            position: "bottom-end",
            timer: 2500,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }

      })
      .catch((error) => {
        console.log("erro: ");
        console.log(error);
      }); 

  },
  updated(){

  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
};
</script>