<template>
  <div class="mt-4 col mt-sm-0">
    <div class="card">
      <div class="p-3 card-body position-relative">
        <div class="row">
          <div class="col text-start">
            <p class="text-sm mb-1 text-uppercase font-weight-bold">
              {{ title }}
            </p>
            <h5 :class="`mb-0 font-weight-bolder text-${color}`">
              {{
                (count).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
              }}
            </h5>
            
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultStatisticsCard",
  props: {
    title: {
      type: String,
      default: "Default Statistics Card",
    },
    count: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "secondary",
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>
