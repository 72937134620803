<template>
  <div class="card mx-4 mt-4">
    <p class="p-2 mb-0">
      <router-link :to="{ name: 'UserApiCreate' }" class="mb-0 btn btn-success btn-irispay">Adicionar usuário da API</router-link>
      &nbsp;
      <a target="_blank" href="https://api.app.rtn.com.br/docs/partner" class="mb-0 btn btn-secondary btn-irispay-outline">Documentação da API</a>
    </p>
    <div class="table-responsive">
      <table class="table table-flush">
        <thead class="thead-light">
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ID</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Email</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Access Token</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>{{ user.id }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.access_token }}</td>
            <td>{{ (user.status==1 ? "Ativo" : "Inativo") }}</td>
            <td>              
              <router-link :to="{ name: 'UserApiEdit', params: { id: user.id } }"><i class="fas fa-fw fa-pen"></i></router-link>
              <a href="#" @click="deleteRecord(user.id)"><i class="fas fa-fw fa-trash-alt"></i></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import api from '@/utils/api.js';

/* eslint-disable vue/no-unused-components */
export default {
  name: "UserApi",
  data() {
    return {
      showMenu: false,
      users: []
    };
  },
  methods:{
    deleteRecord: function(id){
      this.$swal({
        title: "Tem certeza?",
        text: "Essa ação não pode ser revertida",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir.",
        cancelButtonText: "Não, cancelar.",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .get(`/user-api/delete?id=${id}`)
            .then(() => {
              this.users = this.users.filter(function( obj ) {
                return obj.id !== id;
              });

              this.$swal({
                title: "Excluido!",
                text: "Registro excluido com sucesso.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 2500,
                timerProgressBar: true,
                showConfirmButton: false,
              });
            })
            .catch((error) => {
              console.log("erro: ");
              console.log(error);
            }); 
        }
      });
    },
    submitForm: function(){
      //panel-partner/beer/create
      let form = document.getElementById("bank_settings_form");
      if(!form){ return; }
      var formData = new FormData(form);

      this.$swal.showLoading();
      api
        .post('/dashboard/bank-settings', formData)
        .then(() => {
          this.$swal.close();
          this.$router.push({ name: 'BankSettings' });
        })
        .catch((error) => {
          if(error.response){
            console.log(error.response);
            this.$swal({
              title: error.response.data.message,
              text: error.response.data.error,
              icon: "error",
              toast: true,
              position: "bottom-end",
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }); 
    },
  },
  watch: {

  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;

    this.$swal.showLoading();

    api
      .get('/user-api')
      .then((response) => {
        this.$swal.close();
        this.users = response.data;

        if(this.$route.params.edited){
          this.$swal({
            title: "Usuário alterado!",
            text: "Usuário alterado com sucesso.",
            icon: "success",
            toast: true,
            position: "bottom-end",
            timer: 2500,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      })
      .catch((error) => {
        console.log("erro: ");
        console.log(error);
      }); 

  },
  updated(){

  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
};
</script>