<template>
    <form @submit.prevent="savePDV">
        <div class="row mb-2">

            <div class="col-12 col-sm-4">
                <label>CEP</label>
                <input 
                class="form-control" 
                type="text" 
                placeholder="CEP"
                v-model="formMutable.zipcode"
                @change="getZipcodeData(formMutable.zipcode)" 
                />
            </div>

            <div class="col-12 col-sm-4">
                <label>Estado</label>
                <select
                    id="choices-state-edit"
                    class="form-control"
                    name="choices-state"
                    v-model="formMutable.state_id"
                    @change="state = formMutable.state_id"
                >
                    <option 
                    v-for="state, index in states"
                    :key="index"
                    :value="index"
                    >
                        {{state}}
                    </option>

                </select>
            </div>

            <div class="col-12 col-sm-4">
                <label>Cidade</label>
                <select
                    id="choices-city-edit"
                    class="form-control"
                    name="choices-city"
                    v-model="formMutable.city_id"
                >
                    <option 
                    v-for="city, index in cities"
                    :key="index"
                    :value="index"
                    >
                        {{city}}
                    </option>

                </select>
            </div>
            
        </div>

        <div class="row mb-2">
            <div class="col-12">
                <label>Endereço</label>
                <input 
                class="form-control" 
                type="text" 
                placeholder="Endereço"
                v-model="formMutable.address" />
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-12 col-sm-6">
                <label>Número</label>
                <input 
                class="form-control" 
                type="number" 
                placeholder="Número" 
                v-model="formMutable.number"/>
            </div>
            <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Complemento</label>
                <input 
                class="form-control" 
                type="text" 
                placeholder="Complemento"
                v-model="formMutable.complement" />
            </div>
        </div>


        <h5 class="mb-3 d-lg-flex mt-3">Usuário</h5>

        <div class="row mb-2">
            <div class="col-12 col-sm-6">
                <label>Nome</label>
                <input class="form-control" type="text" placeholder="Nome" v-model="formMutable.name" />
            </div>

            <div class="col-12 col-sm-6">
                <label>Documento</label>
                <input 
                class="form-control" 
                type="text" 
                placeholder="Documento"
                v-model="formMutable.document" />
            </div>

        </div>

        <div class="row mb-2">
            <div class="col-12">
                <label>Email</label>
                <input 
                class="form-control" 
                type="email" 
                placeholder="Email" 
                v-model="formMutable.email"
                required
                />
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-12 col-sm-6">
                <label>Senha</label>
                <input class="form-control" type="password" placeholder="Senha" v-model="formMutable.password" />
            </div>
            <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Verificar senha</label>
                <input class="form-control" type="password" placeholder="Verificar senha" v-model="formMutable.passwordVerify" />
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-12 col-sm-6">
                <label>Torneira</label>
                <select
                    class="form-control"
                    name="choices-state"
                    v-model="tapToBeAdded"
                >
                    <option value="" disabled selected>Selecione uma torneira</option>
                    <option 
                    v-for="tap, index in availableTaps"
                    :key="index"
                    :value="index"
                    >
                        {{tap}}
                    </option>

                </select>
            </div>
            <div class="col-12 col-sm-6">
                <label></label>
                <argon-button 
                class="form-control btn-irispay-outline"
                color="success" 
                size="md" 
                variant="outline"
                @click="addTap"
                type="button"
                >
                    Adicionar torneira
                </argon-button>
            </div>
        </div>

        <div class="row mb-2">
                <label>Torneiras: </label>
                <div class="table-responsive">
                <table id="taps" class="table table-flush">
                    <tbody>
                    <tr v-for="tap in taps" :key="tap.id">
                        <td class="text-sm">{{tap.id}}</td>
                        <td class="text-sm">{{tap.name}}</td>
                        <td class="text-sm">{{tap.mac}}</td>
                        <td class="text-sm">
                        <a
                            href="#taps"
                            @click="removeTap(tap.id)"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Excluir torneira do PDV"
                        >
                            <i class="fas fa-trash text-secondary"></i>
                        </a>
                        </td>
                    </tr>

                    </tbody>
                </table>
                </div>
        </div>

        <div class="row mb-2 mt-3">
            <argon-button 
            class="form-control btn-irispay"
            color="success" 
            size="md" 
            variant="gradient"
            >
            Salvar
            </argon-button>
        </div>

    </form>

</template>

<script>
import api from "@/utils/api.js";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
    components: {
        ArgonButton,
    },
    emits: ["savePDV"],

    props: {
        form: {
            type: Object,
            default() {
                return {
                    name: "",
                    address: "",
                    email: "",
                    document: "",
                    zipcode: "",
                    number: 0,
                    complement: "",
                    state_id: 0,
                    city_id: 0,
                    password: "",
                    passwordVerify: "",
                    pdvTaps:[],
                }
            }
        },
    },
    data() {
        return {
            formMutable: this.form,
            states: [],
            cities: [],
            state: 0,
            availableTaps: [],
            taps: [],
            tapToBeAdded:"",
            showErrorPassword: false,
        }
    },
    mounted(){

        this.state = this.formMutable.state_id;

        api
            .get('/partner/get-states')
            .then((response) => {
                this.states = response.data;
            })
            .catch((error) => {
                console.log("erro ao obter estados: ");
                console.log(error);
            });

        api
            .get('/dashboard/get-pdv-available-taps')
            .then((response) => {

                this.availableTaps = response.data;

            })
            .catch((error) => {
                console.log("erro ao obter torneiras disponíveis: ");
                console.log(error);
            });

        if(this.form.pdvTaps){

            this.form.pdvTaps.forEach(tap => {
                api
                .get('/dashboard/find-pdvtap?tapId=' + tap)
                .then((response) => {
                    this.taps.push({
                        id: response.data.id,
                        name: response.data.name,
                        mac: response.data.mac,
                    });
                })
                .catch((error) => {
                    console.log("erro ao obter dados das torneiras do PDV: ");
                    console.log(error);

                });
            });

            this.formMutable.pdvTaps = [];
        } 

    },
    watch: {
        state(newState) {
            api
                .get('/partner/get-cities?stateId=' + newState)
                .then((response) => {
                    this.cities = response.data;
                })
                .catch((error) => {
                    console.log("erro ao obter Cidades: ");
                    console.log(error);

                });
        },
    },
    methods: {
        savePDV(){
            this.$emit("savePDV", this.formMutable);
        },
        getZipcodeData(zipcode){
            api
                .get('/partner/zipcode?zipcode=' + zipcode)
                .then((response) => {

                    this.formMutable.zipcode = zipcode;
                    this.formMutable.address = response.data.address;
                    this.formMutable.state_id = response.data.stateId;
                    this.state = response.data.stateId;
                    this.formMutable.city_id = response.data.city_id;

                })
                .catch((error) => {
                    console.log("erro ao obter dados do CEP: ");
                    console.log(error);

                });
        },
        addTap(){
            console.log("teste adicionar torneira");
            console.log(this.tapToBeAdded);

            api
                .get('/dashboard/find-pdvtap?tapId=' + this.tapToBeAdded)
                .then((response) => {

                   this.taps.push({
                       id: response.data.id,
                       name: response.data.name,
                       mac: response.data.mac,
                   });

                   this.formMutable.pdvTaps.push(response.data.id)

                    console.log(this.taps);
                    console.log(this.form.pdvTaps);

                })
                .catch((error) => {
                    console.log("erro ao obter dados da torneira: ");
                    console.log(error);
                });
        },
        removeTap(tapId){

            const index = this.taps.findIndex(i => i.id === tapId);;

            if (index > -1) {
                this.taps.splice(index, 1); 
            }

            api
                .get('/dashboard/delete-tap-pdv?id=' + tapId)
                .then(() => {
                    console.log("Torneira removida com sucesso!");
                    //this.$router.go();
                })
                .catch((error) => {
                    console.log("erro ao remover torneira: ");
                    console.log(error);
                });
        }

    }
}
</script>