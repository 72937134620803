<template>
  <div class="card mx-4 mt-4 p-4">
    <form action="POST" id="create_product_form">
      <div class="row">
        <div class="row">
          <div class="col mb-1 text-center">
            Imagem<br>
            <img style="max-width:100%; min-height: 100px;" :src="logoUrl" alt="TESTE"/>
          </div>
        </div>
        <div class="col-md-6 mb-1">
          Nome<br>
          <input type="text" class="form-control" :value="nome" readonly>
        </div>
        <div class="col-md-6 mb-1">
          Categoria<br>
          <input type="text" class="form-control" :value="categoria" readonly>
        </div>

        <div class="col-md-6 mb-1">
          Marca<br>
          <input type="text" class="form-control" :value="marca" readonly>
        </div>

        <div class="col-md-6 mb-1">
          Preço<br>
          <input type="number" class="form-control" name="value" :value="preco">
        </div>

        <div class="col-md-12 mb-1">
          Descrição<br>
          <textarea rows="3" :value="descricao" readonly class="form-control"></textarea>
        </div>

        <div class="col-md-12 mt-4">
          <button type="button" @click="submitForm()" class="btn w-100 btn-success btn-irispay">Atualizar produto</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import api from '@/utils/api.js';

export default {
  name: "ProductCreate",
  data() {
    return {
      showMenu: false, 
      logoUrl: "https://partner.rtn.com.br/images/iris-hor.png",
      nome: "",
      categoria: "",
      marca: "",
      descricao: "",
      preco: "",
      products: [],
    };
  },
  methods:{
    submitForm: function(){
      //panel-partner/beer/create
      let form = document.getElementById("create_product_form");
      if(!form){ return; }
      var formData = new FormData(form);

      /* for(let [name, value] of formData) {
        console.log(`${name} = ${value}`); // key1 = value1, then key2 = value2
      } */

      this.$swal.showLoading();
      api
        .post('/product-partner/update?id=' + this.$route.params.id, formData)
        .then((response) => {
          this.$swal.close();

          if(response.data.type == 'danger'){
            this.$swal({
              title: 'Erro!',
              text: response.data.message,
              icon: "error",
              toast: true,
              position: "bottom-end",
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
          else
          {
            this.$router.push({ name: 'Product' });
          }
        })
        .catch((error) => {
          if(error.response){
            console.log(error.response);
            this.$swal({
              title: error.response.data.message,
              text: error.response.data.error,
              icon: "error",
              toast: true,
              position: "bottom-end",
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }); 
    }
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;

    this.$swal.showLoading()
    api
      .get('/product-partner/get-product-data?id=' + this.$route.params.id + '&expand=product,product.category')
      .then((response) => {
        this.$swal.close()
        console.log("informações do produto: ");
        console.log(response.data);
        this.nome = response.data.product.name
        this.categoria = response.data.product.category.name
        this.descricao = response.data.product.description
        this.marca = response.data.product.brand
        this.preco = response.data.value
        this.logoUrl = 'http://assets.rtn.com.br/uploads/product/image/thumbs/small/'+response.data.product.image;
      })
      .catch((error) => {
          this.$swal.close()
          console.log("erro ao consultar dados do produto: ");
          console.log(error);
      });
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
};
</script>