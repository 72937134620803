<template>

<div class="position-fixed top-1 end-1 z-index-2">
  <argon-snackbar
    v-if="snackbar"
    title="Mensagem!"
    :icon="{ component: 'ni ni-spaceship', color: 'white' }"
    :color="snackbar.color"
    :description="snackbar.message"
    :close-handler="closeSnackbar"
  />
</div>

  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background: black;
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7 mt-2">
            <div class="border-0 mb-0">
              <div class="card-header bg-transparent">

                <img class="mx-auto d-block" src="../../../assets/img/iris-hor.png" alt="">

                <h5 class="text-irispay text-center mt-2 mb-3">Recuperar senha</h5>
              </div>
              <div class="card-body px-lg-5 pt-0">
                <form role="form" class="text-start">
                  <div class="mb-3">
                    <argon-input
                      id="email"
                      type="email"
                      placeholder="Email"
                      aria-label="Email"
                      isRequired
                      @input="loginForm.email = $event.target.value"
                    />
                  </div>
                </form>

                <div class="text-center">
                    <argon-button
                      color="success"
                      variant="gradient"
                      full-width
                      class="my-1 mb-2 btn-irispay "
                      @click="sendEmail"
                      >
                        Enviar
                      </argon-button
                    >
                  </div>
                  <div class="text-center">
                    <argon-button
                      color="dark"
                      variant="outline"
                      full-width
                      class="mt-2 mb-4 btn-irispay-outline"
                      @click="returnToLogin"
                      >
                      
                        Voltar
                      
                      </argon-button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import api from "@/utils/api.js";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSnackbar from "@/components/ArgonSnackbar.vue";
import { useRouter } from 'vue-router';
import { mapMutations } from "vuex";

export default {
  name: "SigninBasic",
  components: {
    ArgonInput,
    ArgonButton,
    ArgonSnackbar,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),

    sendEmail() {

      this.$swal.showLoading()

      api
        .post('/login/reset-password-request', this.loginForm)
        .then((response) => {

          this.$swal.close()

          this.snackbar = {
            message: response.data.message,
            color: response.data.type,
          }
          
          console.log("reset password: ");
          console.log(response.data);

        })
        .catch((error) => {

          this.$swal.close();

          console.log("erro ao solicitar nova senha: ");
          console.log(error);

        }); 

    },
    closeSnackbar() {
      this.snackbar = null;
    },
    returnToLogin(){
      this.router.push('/login');
    }
  },
  data() {
    return {
      loginForm: {
        email: '',
      },
      router: useRouter(),
      snackbar: null,
    }
  },
};
</script>
