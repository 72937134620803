<template>

  <div class="py-4 container-fluid">

    <h3 class="text-irispay">Vendas</h3>

    <div class="row mb-2">
      <default-statistics-card
        title="Créditos"
        :count="entry"
        color='dark'
      />

      <default-statistics-card
        title="Retiradas"
        :count="out"
        color='dark'
      />

      <default-statistics-card
        title="Saldo"
        :count="total"
        :color="total < 0 ?'danger' : 'success'"
      />
    </div>
    

    <div class="row mb-2">
      <div class="col-12">

        <form @submit.prevent="applyFilters">

          <div class="card">

            <div class="pb-0 card-header">
              <div class="d-lg-flex">
                <div>
                  <h5 class="mb-0">Filtros</h5>
                </div>
              </div>
              
            </div>

            <div class="px-4 pb-2 card-body">
              <div class="row">
                <div class="col">
                  <label>Filial</label>
                  <input class="form-control" type="text" v-model="filter.partner_id">
                </div>
                <div class="col">
                  <label>PDV</label>
                  <input required class="form-control" type="text" v-model="filter.pdv_name">
                </div>
                <div class="col">
                  <label>Torneira</label>
                  <input class="form-control" type="number" v-model="filter.tap_id">
                </div>
                <div class="col">
                  <label>Bebida</label>

                  <select
                  class="form-control"
                  name="choices-state"
                  v-model="filter.beer_id"
                  >
                      <option value="" disabled selected>Selecione uma bebida</option>
                      <option 
                      v-for="beer, index in beer_items"
                      :key="index"
                      :value="index"
                      >
                          {{beer}}
                      </option>

                  </select>
                </div>
                <div class="col">
                  <label>Valor</label>
                  <input class="form-control" type="string" v-model.lazy="filter.value" v-money="money">
                </div>
                <div class="col">
                  <label>Volume (ml)</label>
                  <input class="form-control" type="number" v-model="filter.amount">
                </div>
                
              </div>

              <div class="row">
                <div class="col">
                  <label>Usuário ID</label>
                  <input class="form-control" type="number" v-model="filter.user_id">
                </div>
                <div class="col">
                  <label>Nome</label>
                  <input class="form-control" type="text" v-model="filter.name">
                </div>
                <div class="col">
                  <label>Data Inicial</label>
                  <input class="form-control" type="date" v-model="filter.initial_date_aux">
                </div>
                <div class="col">
                  <label>Hora Inicial</label>
                  <input class="form-control" type="Time" v-model="filter.initial_hour">
                </div>
                <div class="col">
                  <label>Data Final</label>
                  <input class="form-control" type="date" v-model="filter.final_date_aux">
                </div>
                <div class="col">
                  <label>Hora Final</label>
                  <input class="form-control" type="Time" v-model="filter.final_hour">
                </div>
                
              </div>
            </div>

            <div class="px-4 pb-2 card-footer">
              <p>Caso não seja aplicado nenhum filtro, por padrão serão exibidos os dados do último 1 mês.</p>
              <argon-button class="m-1 btn-irispay" type="submit">Filtrar</argon-button>
              <argon-button class="m-1 btn-irispay" type="button" @click="removeFilters">Limpar filtros</argon-button>        
            </div>
            
          </div>

        </form>
      </div>
    </div>


    <div class="row">
      <div class="col-12">

        <div class="card">

          <div class="pb-2 card-body">

            <div class="table-responsive">
              <div class="row p-3">
                {{ getItemsOnPage() }}
              </div>
              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th class="fs-6">Codigo</th>
                    <th class="fs-6">Filial</th>
                    <th class="fs-6" width="5%">PDV</th>
                    <th class="fs-6">Torneira</th>
                    <th class="fs-6">Bebida</th>
                    <th class="fs-6">Valor</th>
                    <th class="fs-6" width="5%">Volume(ml)</th>
                    <th class="fs-6" width="5%">Usuário ID</th>
                    <th class="fs-6">Nome</th>
                    <th class="fs-6">Data Inicial</th>
                    <th class="fs-6">Data Final</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="beeroutItem in beerout" :key="beeroutItem.id">
                    <td class="text-sm text-wrap">{{beeroutItem.id}}</td>
                    <td class="text-sm text-wrap">{{beeroutItem.partner_name}}</td>
                    <td class="text-sm text-wrap">{{beeroutItem.pdv_name}}</td>
                    <td class="text-sm text-wrap">{{beeroutItem.tap_name}}</td>
                    <td class="text-sm text-wrap">{{beeroutItem.beer_name}}</td>
                    <td class="text-sm text-wrap">R$ {{beeroutItem.value}}</td>
                    <td class="text-sm text-wrap">{{beeroutItem.amount}}</td>
                    <td class="text-sm text-wrap">{{beeroutItem.user_id}}</td>
                    <td class="text-sm text-wrap">{{beeroutItem.user_name}}</td>
                    <td class="text-sm text-wrap">{{timestampToDate(beeroutItem.created_at)}}</td>
                    <td class="text-sm text-wrap">{{timestampToDate(beeroutItem.updated_at)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="mt-3 d-flex justify-content-around">

              <Pagination-Iris
                :totalPages="number_pages"
                :atualPage="filter.page"
                @goToPage="(n) => filter.page = n"
               />
                
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/utils/api.js";
import ArgonButton from "@/components/ArgonButton.vue";
import DefaultStatisticsCard from "./../components/DefaultStatisticsCard.vue";
import PaginationIris from "@/components/PaginationIris.vue";
import {VMoney} from 'v-money'



export default {
  components: {
    ArgonButton,
    PaginationIris,
    DefaultStatisticsCard,

  },
  directives: {money: VMoney},
  data() {
    return {
      filter: {
        partner_id: null,
        pdv_name: null,
        tap_id: null,
        beer_id: null,
        value: null,
        amount: null,
        user_id: null,
        name: null,
        initial_date_aux: null,
        created_at: null,
        initial_hour: null,
        final_date_aux: null,
        updated_at: null,
        final_hour: null,
        page: 1,
      },
      entry: 0,
      out: 0,
      total: 0,
      beerout: null,
      number_pages: null,
      total_items: null,
      beer_items: null,
      money: {
          decimal: '.',
          thousands: ',',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false
        }
    };
  },
  beforeMount() {
    this.$store.state.layout = "custom";

    this.$swal.showLoading()

    api
      .get('/dashboard/beer-out',{
        params: {},
      })
      .then((response) => {
          this.beerout = response.data.data;
          this.number_pages = response.data.number_pages
          this.total_items = response.data.number_items_total

          console.log("Informações iniciais salvas...");

          api
            .get('/partner/get-beer-items')
            .then((response) => {

                this.beer_items = response.data;

                this.$swal.close()
                
                console.log("Bebidas salvas...");

                api
                .get('/dashboard/beer-out-card-info',{
                  params: {},
                })
                .then((response2) => {

                  this.entry = response2.data.credit_entry
                  this.out = response2.data.credit_out
                  this.total = response2.data.total

                })
            })
            .catch((error) => {
                this.$swal.close()
                console.log("erro ao recuperar dados de bebidas: ");
                console.log(error);
            });
      })
      .catch((error) => {
          this.$swal.close()
          console.log("erro ao recuperar dados de vendas: ");
          console.log(error);
      });

    

  },
  watch: {
    'filter.page'(){
      this.$swal.showLoading()
      api
        .get('/dashboard/beer-out',{
          params: this.filter,
        })
        .then((response) => {
            this.beerout = response.data.data;
            this.number_pages = response.data.number_pages
            this.total_items = response.data.number_items_total

            this.$swal.close();

                api
                .get('/dashboard/beer-out-card-info',{
                  params: this.filter,
                })
                .then((response2) => {

                  this.entry = response2.data.credit_entry
                  this.out = response2.data.credit_out
                  this.total = response2.data.total

                })

            console.log("Dados carregados para a página ", this.filter.page);
        })
        .catch((error) => {
            this.$swal.close();
            console.log("erro ao recuperar dados de vendas na página ", this.filter.page);
            console.log(error);
        });
    }
  },
  methods: {
    applyFilters(){
      console.log("Aplicando filtros...");

      if(this.filter.initial_date_aux){
        let date = this.filter.initial_date_aux.split("-");

        date = date[2] + "/" + date[1] + "/" + date[0]

        if(this.filter.initial_hour){
          date = date + " " + this.filter.initial_hour
        }
        else {
          date = date + " __:__"
        }

        this.filter.created_at = date;
        
      }

      if(this.filter.final_date_aux){
        let date = this.filter.final_date_aux.split("-");

        date = date[2] + "/" + date[1] + "/" + date[0]

        if(this.filter.final_hour){
          date = date + " " + this.filter.final_hour
        }
        else {
          date = date + " __:__"
        }

        this.filter.updated_at = date;
        
      }

      console.log(this.filter);

      let aux = this.filter.value

      this.filter.value = parseFloat(aux);

      this.$swal.showLoading()

      api
        .get('/dashboard/beer-out', {
          params: this.filter,
        })
        .then((response) => {
          
            console.log("Vendas filtradas...");
            console.log(this.filter);

            this.beerout = response.data.data
            this.entry = response.data.credit_entry
            this.out = response.data.credit_out
            this.total = response.data.total
            this.number_pages = response.data.number_pages
            this.total_items = response.data.number_items_total

            this.filter.value = aux;
            this.$swal.close()
        })
        .catch((error) => {
            this.$swal.close()
            console.log("erro ao filtrar vendas...");
            console.log(error);
        });
    },
    removeFilters(){

      console.log("Removendo filtros...");

      this.filter = {
        partner_id: null,
        pdv_name: null,
        tap_id: null,
        beer_id: null,
        value: null,
        amount: null,
        user_id: null,
        name: null,
        initial_date_aux: null,
        created_at: null,
        initial_hour: null,
        final_date_aux: null,
        updated_at: null,
        final_hour: null,
        page: 1,
      },

      this.$swal.showLoading()

      api
        .get('/dashboard/beer-out', {
          params: {},
        })
        .then((response) => {
            console.log("Filtros removidos...");

            this.beerout = response.data.data
            this.entry = response.data.credit_entry
            this.out = response.data.credit_out
            this.total = response.data.total
            this.number_pages = response.data.number_pages
            this.total_items = response.data.number_items_total
            this.$swal.close()
        })
        .catch((error) => {
            this.$swal.close()
            console.log("erro ao remover filtros...");
            console.log(error);
        });

    },
    getItemsOnPage(){
        return `Exibindo de ${ (this.filter.page - 1) * 30 } a ${ this.filter.page * 30 } de ${this.total_items} itens.`;
    },
    timestampToDate(timestamp){
      return new Date(timestamp*1000).toLocaleString("pt-Br");
    },
  },

};
</script>
