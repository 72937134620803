<template>

  <template v-if="totalPages <= 10">

    <argon-pagination>

      <argon-pagination-item 
        v-for="page in totalPages"
        :key="page"
        @click="$emit('goToPage', page)"
        :label="page.toString()" 
        :active="page == atualPage ? true : false"
      />

    </argon-pagination>
  
  </template>

  <template v-else>

    <argon-pagination>

      <argon-pagination-item prev /> 

      <argon-pagination-item 
        v-for="page in getPagesToShow()"
        :key="page"
        @click="$emit('goToPage', page)"
        :label="page.toString()" 
        :active="page == atualPage ? true : false"
      />

      <argon-pagination-item next />

    </argon-pagination>
  
  </template>
  

</template>

<script>
import ArgonPagination from "@/components/ArgonPagination.vue";
import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
export default {
  name: "PaginationIris",
  components: {
    ArgonPagination,
    ArgonPaginationItem,
  },
  props: {
    atualPage: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 5,
    },
  },
  methods: {
    getPagesToShow(){
            
      let pages = [];
      let i = this.atualPage;

      while( i > 0){
        pages.push(i);
        i--;

        if(this.atualPage - i > 5)
          break
      }

      i = this.atualPage + 1


      while(i <= this.totalPages){
        pages.push(i);
        i++;
        
        if( i > this.totalPages || i > this.atualPage + 5 )
          break;
      }

    return pages.sort(function(a, b){return a-b});
    }


      
  }
};
</script>
