<template>
  <div class="card mx-4 mt-4 p-4">
    <form action="POST" id="edit_user_api_form">
      <div class="row">
        <div class="col-md-12 mb-1">
          Email<br>
          <input type="email" :value="this.email" name="UserApi[email]" class="form-control">
        </div>
        <div class="col-md-12 mb-1">
          Status&nbsp;
          <label class="switch">
            <input value="1" :checked="this.status" type="checkbox" name="UserApi[status]">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="col-md-12 mt-4">
          <button type="button" @click="submitForm()" class="btn w-100 btn-irispay">Salvar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import api from '@/utils/api.js';

/* eslint-disable vue/no-unused-components */
export default {
  name: "UserApiCreate",
  data() {
    return {
      showMenu: false,
      id: null,
      email: "",
      status: 0,
    };
  },
  methods:{
    submitForm: function(){
      //panel-partner/beer/create
      let form = document.getElementById("edit_user_api_form");
      if(!form){ return; }
      var formData = new FormData(form);
      if(!formData.has("UserApi[status]"))
      {
        formData.append("UserApi[status]", 0);
      }

      this.$swal.showLoading();
      api
        .post(`/user-api/update?id=${this.id}`, formData)
        .then((response) => {
          this.$swal.close();

          if(response.data.type == 'danger')
          {
            this.$swal({
              title: 'Erro!',
              text: response.data.message,
              icon: "error",
              toast: true,
              position: "bottom-end",
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
          else {
            this.$swal({
              title: 'Sucesso!',
              text: response.data.message,
              icon: "success",
              toast: true,
              position: "bottom-end",
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
            this.$router.push({ name: 'UserApi', params: { edited: true} });
          }
        })
        .catch((error) => {
          if(error.response){
            console.log(error.response);
            this.$swal({
              title: error.response.data.message,
              text: error.response.data.error,
              icon: "error",
              toast: true,
              position: "bottom-end",
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }); 
    },
  },
  watch: {

  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  beforeMount() {
    this.$store.state.layout = "custom";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = false;
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = true;

    this.id = this.$route.params.id;
    if(!this.id){
      this.$router.push({ name: 'UserApi' });
      return;
    }
    this.$swal.showLoading();
    api
      .get('user-api/view?id='+this.id)
      .then((response) => {
        this.$swal.close();
        console.log(response.data);

        this.email = response.data.email;
        this.status = response.data.status;
      })
      .catch((error) => {
        console.log("erro: ");
        console.log(error);
      }); 
  },
  updated(){

  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  },
};
</script>