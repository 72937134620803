<template>

  <div class="py-4 container-fluid">
    <h3 class="text-irispay">Vendas Funcionário</h3>

    <div class="row mb-2">
      <div class="col-12">

        <form @submit.prevent="applyFilters">

          <div class="card">

            <div class="pb-0 card-header">
              <div class="d-lg-flex">
                <div>
                  <h5 class="mb-0">Filtros</h5>
                </div>
              </div>
              
            </div>

            <div class="px-4 pb-2 card-body">
              <div class="row">
                <div class="col">
                  <label>Torneira</label>
                  <select
                      id="choices-state-edit"
                      class="form-control"
                      name="choices-state"
                      v-model="filter.beertap_id"
                  >
                    <option :value="null" disabled selected>Filtrar por torneira</option>
                      <option 
                      v-for="mac, index in beertap_items"
                      :key="index"
                      :value="index"
                      >
                          {{mac}}
                      </option>

                  </select>

                </div>
                <div class="col">

                  <label>Fabricante</label>
                  <select
                      id="choices-state-edit"
                      class="form-control"
                      name="choices-state"
                      v-model="filter.manufacturer_id"
                  >
                    <option :value="null" disabled selected>Filtrar por fabricante</option>
                      <option 
                      v-for="manufacturer_name, index in manufacturer_list"
                      :key="index"
                      :value="index"
                      >
                          {{manufacturer_name}}
                      </option>

                  </select>

                </div>
                <div class="col">
                  <label>Estado</label>
                  <select
                      id="choices-state-edit"
                      class="form-control"
                      name="choices-state"
                      v-model="filter.state_id"
                      @change="state = filter.state_id"
                  >
                    <option :value="null" disabled selected>Filtrar por estado</option>
                      <option 
                      v-for="state, index in states"
                      :key="index"
                      :value="index"
                      >
                          {{state}}
                      </option>

                  </select>
                </div>

                <div class="col">
                  <label>Cidade</label>
                  <input class="form-control" type="number" v-model="filter.city_id">
                </div>
                
                <div class="col">
                  <label>Bebida</label>

                  <select
                  class="form-control"
                  name="choices-state"
                  v-model="filter.beer_id"
                  >
                      <option :value="null" disabled selected>Filtrar por bebida</option>
                      <option 
                      v-for="beer, index in beer_items"
                      :key="index"
                      :value="index"
                      >
                          {{beer}}
                      </option>

                  </select>
                </div>
                <div class="col">
                  <label>Valor</label>
                  <input class="form-control" type="number" v-model="filter.value">
                </div>
                
                
              </div>

              <div class="row">

                <div class="col">
                  <label>Volume (ml)</label>
                  <input class="form-control" type="number" v-model="filter.amount">
                </div>
                
                <div class="col">
                  <label>Usuário</label>
                  <input class="form-control" type="text" v-model="filter.user_name">
                </div>

                <div class="col">
                  <label>Funcionário</label>
                  <input class="form-control" type="text" v-model="filter.employee">
                </div>

                <div class="col">
                  <label>Idade mínima</label>
                  <input class="form-control" type="number" v-model="filter.user_start_age">
                </div>

                <div class="col">
                  <label>Idade máxima</label>
                  <input class="form-control" type="number" v-model="filter.user_end_age">
                </div>

                <div class="col">
                  <label>Gênero</label>
                  <select
                  class="form-control"
                  name="choices-state"
                  v-model="filter.user_gender"
                  >
                      <option :value="null" disabled selected>Filtrar por gênero</option>
                      <option 
                      v-for="gender, index in gender_list"
                      :key="index"
                      :value="index"
                      >
                          {{gender}}
                      </option>
                  </select>
                </div>              
                
              </div>

              <div class="row">              
                <div class="col">

                  <label>Estilo</label>
                  <select
                  class="form-control"
                  name="choices-state"
                  v-model="filter.beerType"
                  >
                      <option :value="null" disabled selected>Filtrar por estilo</option>
                      <option 
                      v-for="type, index in beer_types"
                      :key="index"
                      :value="index"
                      >
                          {{type}}
                      </option>
                  </select>
                </div>

                <div class="col">
                  <label>Data Inicial</label>
                  <input class="form-control" type="date" v-model="filter.initial_date_aux">
                </div>
                <div class="col">
                  <label>Hora Inicial</label>
                  <input class="form-control" type="Time" v-model="filter.initial_hour">
                </div>
                <div class="col">
                  <label>Data Final</label>
                  <input class="form-control" type="date" v-model="filter.final_date_aux">
                </div>
                <div class="col">
                  <label>Hora Final</label>
                  <input class="form-control" type="Time" v-model="filter.final_hour">
                </div>

              </div>
            </div>

            <div class="px-4 pb-2 card-footer">
              <p>Caso não seja aplicado nenhum filtro, por padrão serão exibidos os dados do último 1 ano.</p>
              <argon-button class="m-1 btn-irispay" type="submit">Filtrar</argon-button>
              <argon-button class="m-1 btn-irispay" type="button" @click="removeFilters">Limpar filtros</argon-button>    
            </div>
            
          </div>
        </form>
      </div>
    </div>


    <div class="row">
      <div class="col-12">

        <div class="card">

          <div class="pb-2 card-body">

            <div class="table-responsive">
              <div class="row p-3">
                {{ getItemsOnPage() }}
              </div>
              <table id="products-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th class="fs-6">ID</th>
                    <th class="fs-6">MAC</th>
                    <th class="fs-6" v-if="user_type === 'main'">Parceiro</th>
                    <th class="fs-6" width="5%">Estilo</th>
                    <th class="fs-6">Bebida</th>
                    <th class="fs-6">Valor</th>
                    <th class="fs-6" width="5%">Volume(ml)</th>
                    <th class="fs-6">Usuário</th>
                    <th class="fs-6">Retirada Inicio</th>
                    <th class="fs-6">Retirada Fim</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="reportItem in reportWithdraw" :key="reportItem.id">
                    <td class="text-sm text-wrap">{{reportItem.id}}</td>
                    <td class="text-sm text-wrap">
                        {{ "#" + reportItem.beertap.id + " " +  reportItem.beertap.mac}}
                    </td>
                    <td class="text-sm text-wrap" v-if="user_type === 'main'">{{reportItem.partner_name}}</td>
                    <td class="text-sm text-wrap">{{reportItem.beerType}}</td>
                    <td class="text-sm text-wrap">{{reportItem.beer.name}}</td>
                    <td class="text-sm text-wrap">R$ {{reportItem.value}}</td>
                    <td class="text-sm text-wrap">{{reportItem.amount}}</td>
                    <td class="text-sm text-wrap">{{reportItem.user_name}}</td>
                    <td class="text-sm text-wrap">{{timestampToDate(reportItem.created_at)}}</td>
                    <td class="text-sm text-wrap">{{timestampToDate(reportItem.updated_at)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="mt-3 d-flex justify-content-around">

              <Pagination-Iris
                :totalPages="number_pages"
                :atualPage="filter.page"
                @goToPage="(n) => filter.page = n"
               />
                
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/utils/api.js";
import ArgonButton from "@/components/ArgonButton.vue";
import PaginationIris from "@/components/PaginationIris.vue";


export default {
  components: {
    ArgonButton,
    PaginationIris,

  },
  data() {
    return {
      filter: {
        partner_id: null,
        manufacturer_id: null,
        beertap_id: null,
        beerType: null,
        beer_id: null,
        state_id:null,
        city_id: null,
        value: null,
        amount: null,
        user_id: null,
        user_name: null,
        employee: null,
        user_start_age: null,
        user_end_age: null,
        user_gender: null,
        initial_date_aux: null,
        created_at: null,
        initial_hour: null,
        final_date_aux: null,
        updated_at: null,
        final_hour: null,
        page: 1,
      },
      reportWithdraw: null,
      number_pages: null,
      total_items: null,
      beer_items: null,
      beer_types: null,
      beertap_items: null,
      manufacturer_list: null,
      states: null,
      gender_list: null,
      user_type: localStorage.getItem('user_type'),
    };
  },
  beforeMount() {
    this.$store.state.layout = "custom";

    api
      .get('/partner/get-states')
      .then((response) => {
          this.states = response.data;
      })
      .catch((error) => {
          console.log("erro ao obter estados: ");
          console.log(error);
      });

      

    api
      .get('/beer/get-beer-types')
      .then((response) => {
          this.beer_types = response.data;
      })
      .catch((error) => {
          console.log("erro ao obter beer_types: ");
          console.log(error);
      });

    api
      .get('/beertap/get-items')
      .then((response) => {
          this.beertap_items = response.data;
      })
      .catch((error) => {
          console.log("erro ao obter beetap_items: ");
          console.log(error);
      });

    api
      .get('/user/get-gender')
      .then((response) => {
          this.gender_list = response.data;
      })
      .catch((error) => {
          console.log("erro ao obter gender_list: ");
          console.log(error);
      });

    api
      .get('/beer/get-array-list-manufacturer')
      .then((response) => {
          this.manufacturer_list = response.data;
      })
      .catch((error) => {
          console.log("erro ao obter manufacturer_list: ");
          console.log(error);
      });

    
    this.$swal.showLoading()

    api
      .get('/report-withdraw/index',{
        params: {},
      })
      .then((response) => {
          this.reportWithdraw = response.data.data;
          this.number_pages = response.data.number_pages
          this.total_items = response.data.number_items_total

          console.log("Informações iniciais salvas...");

          api
            .get('/partner/get-beer-items')
            .then((response) => {

                this.beer_items = response.data;

                this.$swal.close()
                
                console.log("Bebidas salvas...");
            })
            .catch((error) => {
                this.$swal.close()
                console.log("erro ao recuperar dados de bebidas: ");
                console.log(error);
            });
      })
      .catch((error) => {
          this.$swal.close()
          console.log("erro ao recuperar dados de vendas: ");
          console.log(error);
      });

    

  },
  watch: {
    'filter.page'(){
      this.$swal.showLoading()
      api
        .get('/report-withdraw/index',{
          params: this.filter,
        })
        .then((response) => {
            this.reportWithdraw = response.data.data;
            this.number_pages = response.data.number_pages
            this.total_items = response.data.number_items_total

            this.$swal.close();

            console.log("Dados carregados para a página ", this.filter.page);
        })
        .catch((error) => {
            this.$swal.close();
            console.log("erro ao recuperar dados de vendas na página ", this.filter.page);
            console.log(error);
        });
    }
  },
  methods: {
    applyFilters(){
      console.log("Aplicando filtros...");

      if(this.filter.initial_date_aux){
        let date = this.filter.initial_date_aux.split("-");

        date = date[2] + "/" + date[1] + "/" + date[0]

        if(this.filter.initial_hour){
          date = date + " " + this.filter.initial_hour
        }
        else {
          date = date + " __:__"
        }

        this.filter.created_at = date;
        
      }

      if(this.filter.final_date_aux){
        let date = this.filter.final_date_aux.split("-");

        date = date[2] + "/" + date[1] + "/" + date[0]

        if(this.filter.final_hour){
          date = date + " " + this.filter.final_hour
        }
        else {
          date = date + " __:__"
        }

        this.filter.updated_at = date;
        
      }

      this.$swal.showLoading()

      api
        .get('/report-withdraw/index', {
          params: this.filter,
        })
        .then((response) => {
          
            console.log("Vendas filtradas...");

            this.reportWithdraw = response.data.data
            this.number_pages = response.data.number_pages
            this.total_items = response.data.number_items_total
            this.$swal.close()
        })
        .catch((error) => {
            this.$swal.close()
            console.log("erro ao filtrar vendas...");
            console.log(error);
        });
    },
    removeFilters(){

      console.log("Removendo filtros...");

      this.filter = {
        partner_id: null,
        manufacturer_id: null,
        beertap_id: null,
        beerType: null,
        beer_id: null,
        value: null,
        amount: null,
        user_id: null,
        state_id: null,
        city_id: null,
        user_name: null,
        employee: null,
        user_start_age: null,
        user_end_age: null,
        user_gender: null,
        initial_date_aux: null,
        created_at: null,
        initial_hour: null,
        final_date_aux: null,
        updated_at: null,
        final_hour: null,
        page: 1,
      },

      this.$swal.showLoading()

      api
        .get('/report-withdraw/index', {
          params: {},
        })
        .then((response) => {
            console.log("Filtros removidos...");

            this.reportWithdraw = response.data.data
            this.number_pages = response.data.number_pages
            this.total_items = response.data.number_items_total
            this.$swal.close()
        })
        .catch((error) => {
            this.$swal.close()
            console.log("erro ao remover filtros...");
            console.log(error);
        });

    },
    getItemsOnPage(){
        return `Exibindo de ${ (this.filter.page - 1) * 30 } a ${ this.filter.page * 30 } de ${this.total_items} itens.`;
    },
    timestampToDate(timestamp){
      return new Date(timestamp*1000).toLocaleString("pt-Br");
    },
  },

};
</script>
